import React, { useState, useMemo } from 'react';
import { Dropdown, Menu, Modal, Pagination, Select, Button, message, Upload, Image, Row, Col, Form, Input, Popconfirm, Divider, Empty } from 'antd';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { api } from '../../services/apiClient';
import { useAuth } from '../../contexts/AuthContext';
import ModalEditingClientes from '../ModalEditingClientes';
import ModalSeeClients from '../ModalSeeClients';
import { MdOutlineRemoveRedEye, MdOutlineDelete } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";


const { Option } = Select; // Importação do Option

const baseURL = 'https://credx-bucket.s3.amazonaws.com/';

const TableClientes = ({ data, fetchData }) => {
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25); // Alterado para número
  const [isModalOpenComprovantes, setIsModalOpenComprovantes] = useState(false);
  const [modalContentComprovantes, setModalContentComprovantes] = useState('');
  const [fotosComprovante, setFotosComprovante] = useState([]);
  const [fotosSelfieDocumento, setFotosSelfieDocumento] = useState([]);
  const [fotosDocumento, setFotosDocumento] = useState([]);
  const [searchBarVisible, setSearchBarVisible] = useState(false);

  const [activeFilters, setActiveFilters] = useState({
    cliente: null,
    etapas: null,
    cpf: null,
    usuario: null,
    operacao: null,
  });

  // Estados separados para armazenar os valores dos Select
  const [clienteSelectValue, setClienteSelectValue] = useState(null);
  const [cpfSelectValue, setCpfSelectValue] = useState(null);
  const [colaboradorSelectValue, setColaboradorSelectValue] = useState(null);

  const uniqueClientes = [...new Set(data.map((item) => item.nome))];
  const uniqueCpfs = [...new Set(data.map((item) => item.cpf))];
  const uniqueColab = [...new Set(data.map((item) => item.usuario.nome))];

  const paginatedData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data
      .filter((row) => {
        return (!activeFilters.cliente || row.nome === activeFilters.cliente) &&
          (!activeFilters.cpf || row.cpf === activeFilters.cpf) &&
          (!activeFilters.colaborador || row.usuario.nome === activeFilters.colaborador);
      })
      .slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSize, data, activeFilters]);

  const formatarComoMoeda = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  const handleFilterChange = (filterType, value) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
    // Atualize os estados dos Selects
    if (filterType === 'cliente') setClienteSelectValue(value);
    if (filterType === 'cpf') setCpfSelectValue(value);
    if (filterType === 'colaborador') setColaboradorSelectValue(value);
  };

  const [clienteSelecionado, setClienteSelecionado] = useState();

  const abrirModalDocs = (urlsFotosDocumento, urlsFotosSelfieDocumento, urlsFotosComprovante, row) => {
    setFotosComprovante(urlsFotosComprovante.map((id) => `${baseURL}${id}`));
    setFotosSelfieDocumento(urlsFotosSelfieDocumento.map((id) => `${baseURL}${id}`));
    setFotosDocumento(urlsFotosDocumento.map((id) => `${baseURL}${id}`));
    setClienteSelecionado(row);
    setIsModalOpenComprovantes(true);
  };

  const isEditable = user.tipoUsuario === 0;

  const [loadingDelete, setloadingDelete] = useState(false);
  const [loadingSaveEditing, setLoadingSaveEditing] = useState(false);
  const handleDelete = async (id, cpf) => {
    setloadingDelete(true);
    try {
      const deletedAt = new Date().toISOString(); // Obtem a data e hora atual em formato ISO 8601
      await api.put(`/cliente`, { cpf, id, deletedAt }); // Envia o ID e a data de exclusão para a API
      message.success('Cliente deletado com sucesso!');
      fetchData(); // Função para recarregar os dados após a deleção
      setloadingDelete(false);
    } catch (error) {
      message.error('Erro ao deletar cliente');
      console.log(error.message);
    }
    setloadingDelete(false);
  };

  const [isModalOpenEdicao, setIsModalOpenEdicao] = useState(false); // Added state for edit modal visibility
  const [editingCliente, setEditingCliente] = useState(null);

  const handleUpdateCliente = async (values) => {
    setLoadingSaveEditing(true);
    try {
      const updatedClienteData = { ...values, usuarioId: user.id };


       const retorno =  await api.put(`/cliente`, updatedClienteData);
       console.log('retorno', retorno)
      message.success('Cliente atualizado com sucesso!');
      setIsModalOpenEdicao(false); // Close modal on success
      fetchData(); // Refresh data
      setLoadingSaveEditing(false);
    } catch (error) {
      console.log('/n/n/n/nerror', error.message);
      message.error('Erro ao atualizar cliente');
      // console.error(error);
    }
    setLoadingSaveEditing(false);
  };

  const startEdit = (cliente) => {
    setEditingCliente(cliente);
    setIsModalOpenEdicao(true); // Open the edit modal
  };

  const handleFiltrarClick = () => {
    setSearchBarVisible(!searchBarVisible);
  };


  const handleCancelarClick = () => {
    setSearchBarVisible(false);
    handleLimparFiltros()
  };

  const [selectedCliente, setSelectedCliente] = useState(null);
  const [selectedCpf, setSelectedCpf] = useState(null);
  const [selectedColaborador, setSelectedColaborador] = useState(null);


  const handleLimparFiltros = () => {
    setActiveFilters({
      cliente: null,
      etapas: null,
      cpf: null,
      usuario: null,
      operacao: null,
    });
    setSelectedCliente(null);
    setSelectedCpf(null);
    setSelectedColaborador(null);
    setCurrentPage(1);
  };

  const getTotalContracts = () => {
    return data.length; // Retorna o número total de itens na lista paginada
  };

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to="/">
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            // href: '',
            title: (
              <>
                <span>Bases</span>
              </>
            ),
          },
          {
            title: 'Clientes',
          },
        ]}
      />
      <div className={styles.top}>
        <div className={styles.contractCounter}>
          <h1>Número de clientes: <span>{getTotalContracts()}</span></h1>
        </div>
        <div className={styles.filtro}>

          <Button onClick={handleFiltrarClick}>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
              <path
                d='M4.66679 3.99984H11.3335L7.99345 8.19984L4.66679 3.99984ZM2.83345 3.73984C4.18012 5.46651 6.66679 8.6665 6.66679 8.6665V12.6665C6.66679 13.0332 6.96679 13.3332 7.33345 13.3332H8.66679C9.03345 13.3332 9.33345 13.0332 9.33345 12.6665V8.6665C9.33345 8.6665 11.8135 5.46651 13.1601 3.73984C13.2365 3.64142 13.2838 3.52352 13.2964 3.39957C13.3091 3.27563 13.2868 3.15061 13.2319 3.03875C13.177 2.9269 13.0918 2.8327 12.986 2.76689C12.8802 2.70108 12.758 2.6663 12.6335 2.6665H3.36012C2.80679 2.6665 2.49345 3.29984 2.83345 3.73984Z'
                fill='#FFFFFF'
              />
            </svg>{' '}
            Filtrar
          </Button>
        </div>
      </div>
      {searchBarVisible && (
        <div className={styles.searchBar}>
          <div className={styles.containerSearchBar}>
            <div className={styles.containerSelectFilter}>
              <div className={styles.label}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 24" fill="none">
                  <path d="M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#E8E8EC" />
                </svg>
                <p style={{ paddingLeft: '.5rem' }}>Pesquisar cliente</p>
              </div>
              <Select
                id='cliente-select'
                showSearch
                allowClear
                placeholder='Selecione o cliente'
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  handleFilterChange('cliente', value);
                  setSelectedCliente(value); // Atualiza o estado selecionado
                }}
                value={selectedCliente} // Sincroniza o estado com o valor do Select
                style={{ marginRight: 8 }}
              >
                {uniqueClientes.map((cliente) => (
                  <Option key={cliente} value={cliente}>
                    {cliente}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={styles.containerSelectFilter}>
              <div className={styles.label}>
                <p>CPF</p>
              </div>
              <Select
                id='cpf-select'
                showSearch
                allowClear
                placeholder='Selecione o CPF'
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  handleFilterChange('cpf', value);
                  setSelectedCpf(value); // Atualiza o estado selecionado
                }}
                value={selectedCpf} // Sincroniza o estado com o valor do Select
                style={{ marginRight: 8 }}
              >
                {uniqueCpfs.map((cpf) => (
                  <Option key={cpf} value={cpf}>
                    {cpf}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={styles.containerSelectFilter}>
              <div className={styles.label}>
                <p>Colaborador</p>
              </div>
              <Select
                id='colaborador-select'
                showSearch
                allowClear
                placeholder='Selecione o Colaborador'
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  handleFilterChange('colaborador', value);
                  setSelectedColaborador(value); // Atualiza o estado selecionado
                }}
                value={selectedColaborador} // Sincroniza o estado com o valor do Select
                style={{ marginRight: 8 }}
              >
                {uniqueColab.map((colab) => (
                  <Option key={colab} value={colab}>
                    {colab}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Divider />
          <div className={styles.buttons}>
            <Button onClick={handleLimparFiltros} className={styles.buttonLimpar}>
              Limpar Filtros
            </Button>
            <Button className={styles.buttonCancelar} onClick={handleCancelarClick}>
              Cancelar
            </Button>
          </div>
        </div>
      )}
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th className={styles.alignItems}>Ações</th>
              <th>Nome</th>
              <th>CPF</th>
              {/* <th>RG</th> */}
              <th>Colaborador</th>
              <th>Valor em OP.</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.length === 0 ? (
              <tr>
                <td colSpan={6}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
                </td>
              </tr>
            ) : (
              paginatedData.map((row, index) => (
                <tr key={index}>
                  <td style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                    <Button icon={<MdOutlineRemoveRedEye />} onClick={() => abrirModalDocs(row.urlsFotosDocumento, row.urlsFotosSelfieDocumento, row.urlsFotosComprovante, row)} />
                    <Button icon={<AiOutlineEdit />} onClick={() => startEdit(row)} />
                    <Popconfirm
                      title="Deseja deletar esse cliente?"
                      onConfirm={() => handleDelete(row['id'], row['cpf'])}
                      okText="Sim"
                      cancelText="Não"
                      disabled={loadingDelete}
                    >
                      <Button icon={<MdOutlineDelete style={{ color: 'red' }} />} loading={loadingDelete} disabled={loadingDelete} />
                    </Popconfirm>
                  </td>
                  <td>{row['nome']}</td>
                  <td>{row['cpf']}</td>
                  {/* <td>{row['rg']}</td> */}
                  <td>{row.usuario.nome}</td>
                  <td className={styles.rowTableValueTotal}>
                    <p>{formatarComoMoeda(row['valorTotalEmOperacao'])}</p>
                  </td>

                </tr>
              )))}
          </tbody>
        </table>
      </div>

      <Pagination
        current={currentPage}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        }}
        pageSize={pageSize}
        total={data.length}
        showSizeChanger
        pageSizeOptions={['5', '10', '15', '25']}
        className={styles.cotnainerPagination}
      />

      <ModalSeeClients
        clienteSelecionado={clienteSelecionado}
        isEditable={isEditable}
        isModalOpenComprovantes={isModalOpenComprovantes}
        setIsModalOpenComprovantes={setIsModalOpenComprovantes}
        fotosDocumento={fotosDocumento}
        fotosSelfieDocumento={fotosSelfieDocumento}
        fotosComprovante={fotosComprovante}
      />
      <ModalEditingClientes editingCliente={editingCliente} isModalOpenEdicao={isModalOpenEdicao} setIsModalOpenEdicao={setIsModalOpenEdicao} handleUpdateCliente={handleUpdateCliente} loadingSaveEditing={loadingSaveEditing} isEditable={isEditable} />
    </div>
  );
};

export default TableClientes;
