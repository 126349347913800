import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { Form, Input, Image, Steps, Row, Col, Button, Popconfirm, QRCode } from 'antd';
import { MdArrowBackIosNew } from 'react-icons/md';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { PIX } from 'react-qrcode-pix';
import { toast } from 'react-toastify';

const baseURL = 'https://credx-bucket.s3.amazonaws.com/';

const StepsSeeClient = ({ data, handleAceitar, handleAprovar,handleAprovarMensagem, initialStep = 0, status, handleRecusar }) => {
  const [step, setStep] = useState(initialStep);

  const handleChangeStep = () => {
    setStep(step + 1);
  };

  const handleChangePrevStep = () => {
    setStep(step - 1);
  };

  const formatarData = (dataString) => {
    if (!dataString) return '';

    const data = new Date(dataString);
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(data);
  };

  useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  const formatarTelefone = (telefone) => {
    const numeros = telefone.replace(/\D/g, '');
    if (numeros.length === 11) {
      return numeros.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else if (numeros.length === 10) {
      return numeros.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return numeros; // Retorna os números sem formatação se não tiver um tamanho esperado
  };

  function detectarTipoChavePix(chavePix) {
    if (/^\d{11}$/.test(chavePix)) {
      return 'Telefone';
    } else if (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(chavePix)) {
      return 'CPF';
    } else if (/\S+@\S+\.\S+/.test(chavePix)) {
      return 'Email';
    } else {
      return 'Desconhecido';
    }
  }

  const [banco, setBanco] = useState({});

  const [codePix, setCodePix] = useState(0);

  useEffect(() => {
    const carregarBanco = async (codigoBanco) => {
      if (codigoBanco) {
        try {
          const resposta = await axios.get(`https://brasilapi.com.br/api/banks/v1/${codigoBanco}`);
          setBanco(resposta.data);
        } catch (erro) {
          console.error('Não foi possível carregar os dados do banco:', erro);
          setBanco({});
        }
      }
    };

    if (data?.cliente.chavePix) {
      carregarBanco(data.cliente.bancoSelecionado);
    }
  }, [data?.cliente.chavePix]);

  const copiarAreaTransferencia = async () => {
    try {
      await navigator.clipboard.writeText(codePix);
      toast.success(`Código copiado com sucesso!!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    } catch (err) {
      toast.error(`Falha ao copiar!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.containerHeaderForm}>
          <div className={styles.containerInfosHeader}>
            <button className={styles.arrowPrev} onClick={handleChangePrevStep} disabled={step === 0}>
              <MdArrowBackIosNew />
            </button>
            <h1>{data?.cliente.nome}</h1>
          </div>
          <div className={styles.headerSteps}>
            {['analise', 'aberto'].includes(status) && (
              <>
                {step < 5 && (
                  <Button className={styles.buttonCadastrarOp} onClick={handleChangeStep}>
                    Próximo
                  </Button>
                )}
                {step === 5 && (
                  <div className={styles.containerButtonAnalise}>
                    <Popconfirm title='Tem certeza que deseja recusar esta operação?' onConfirm={() => handleRecusar(data.id)} onCancel={() => console.log('Cancelado')} okText='Sim' cancelText='Não'>
                      <Button className={styles.buttonRecusar}>Recusar</Button>
                    </Popconfirm>
                    <Popconfirm title='Tem certeza que deseja aceitar esta operação?' onConfirm={() => handleAceitar(data.id)} onCancel={() => console.log('Cancelado')} okText='Sim' cancelText='Não'>
                      <Button className={styles.buttonAceitar}>Aceitar</Button>
                    </Popconfirm>
                  </div>
                )}
              </>
            )}
            {status === 'aprovado' && (
              <div className={styles.containerButtonAnalise}>
                {step < 6 && (
                  <Button type='primary' onClick={handleChangeStep}>
                    Próximo
                  </Button>
                )}
                {step === 6 && (
                  <>
                  <Popconfirm title='Tem certeza de que deseja marcar como pago?' onConfirm={() => handleAprovar(data.id)} onCancel={() => console.log('Pagamento cancelado')} okText='Sim' cancelText='Não'>
                    <Button className={styles.buttonAceitar}>Pago</Button>
                  </Popconfirm>
                  <Popconfirm title='Tem certeza de que deseja marcar como pago?' onConfirm={() => handleAprovarMensagem(data.id)} onCancel={() => console.log('Pagamento cancelado')} okText='Sim' cancelText='Não'>
                    <Button className={styles.buttonAceitar}>Pagar e enviar mensagem</Button>
                  </Popconfirm>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <Steps current={step} className={styles.stepsContainer} direction='horizontal'>
          {['', '', '', '', '', '', ''].map((title) => (
            <Steps.Step key={title} title={title} />
          ))}
        </Steps>
        <Form layout='vertical'>
          <Row gutter={[16, 16]}>
            {step === 0 && (
              <>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='Nome'>
                    <Input placeholder='Digite o nome' type='text' value={data?.cliente.nome} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='Apelido'>
                    <Input placeholder='Digite o apelido' value={data?.cliente.apelido} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='RG' className={styles.formItem}>
                    <div className={styles.inputGroup}>
                      <InputMask mask='99.999.999-9' value={data?.cliente.rg} disabled>
                        {(inputProps) => <Input {...inputProps} disabled />}
                      </InputMask>
                    </div>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='CPF' className={styles.formItem}>
                    <div className={styles.inputGroup}>
                      <InputMask mask='999.999.999-99' value={data?.cliente.cpf} disabled>
                        {(inputProps) => <Input {...inputProps} disabled />}
                      </InputMask>
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='Data de nascimento'>
                    <Input value={formatarData(data?.cliente.dataNascimento)} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Celular'>
                    <Input value={formatarTelefone(data?.cliente?.celular)} disabled />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item label='Telefone referência'>
                    <Input value={formatarTelefone(data?.cliente.telefoneResidencial)} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item label='Referencia'>
                    <Input value={data?.cliente.nomeReferencia} disabled />
                  </Form.Item>
                </Col>
              </>
            )}
            {step === 1 && (
              // Endereço
              <>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='CEP - Residencial'>
                    <InputMask mask='99999-999' value={data?.cliente.cepResidencial} disabled>
                      {(inputProps) => <Input {...inputProps} disabled />}
                    </InputMask>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Logradouro'>
                    <Input value={data?.cliente.logradouroResidencial} disabled />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Bairro'>
                    <Input value={data?.cliente.bairroResidencial} disabled />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={16} lg={16}>
                  <Form.Item label='Cidade'>
                    <Input value={data?.cliente.cidadeResidencial} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <Form.Item label='Estado'>
                    <Input value={data?.cliente.estadoResidencial} disabled />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={4} lg={4}>
                  <Form.Item label='Número'>
                    <Input value={data?.cliente.numeroResidencial} disabled />
                  </Form.Item>
                </Col>

                <hr />

                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='CEP - Comercial'>
                    <InputMask mask='99999-999' value={data?.cliente.cepComercial} disabled>
                      {(inputProps) => <Input {...inputProps} disabled />}
                    </InputMask>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Logradouro'>
                    <Input value={data?.cliente.logradouroComercial} disabled />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Bairro'>
                    <Input value={data?.cliente.bairroComercial} disabled />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={16} lg={16}>
                  <Form.Item label='Cidade'>
                    <Input value={data?.cliente.cidadeComercial} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <Form.Item label='Estado'>
                    <Input value={data?.cliente.estadoComercial} disabled />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={4} lg={4}>
                  <Form.Item label='Número'>
                    <Input value={data?.cliente.numeroComercial} disabled />
                  </Form.Item>
                </Col>
              </>
            )}
            {step === 2 && (
              <>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Profissao'>
                    <Input value={data?.cliente.profissao} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Chave pix'>
                    <Input value={data?.cliente.chavePix} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Data de pagamento'>
                    <Input value={formatarData(data?.cliente.dataPagamento)} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Data de vale'>
                    <Input value={formatarData(data?.cliente.dataVale)} disabled />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Nome favorecido'>
                    <Input value={data?.cliente.nomeFavorecido} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Banco'>
                    <Input value={data?.cliente.bancoSelecionado} disabled />
                  </Form.Item>
                </Col>
              </>
            )}
            {step === 3 && (
              <>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='Observação'>
                    <Input value={data?.cliente.observacao} />
                  </Form.Item>
                </Col>
              </>
            )}
            {step === 4 && (
              <>
                <div className={styles.containerFotos}>
                  <div className={styles.containerFotos}>
                    <div>
                      <h2 className={styles.tituloImages}>Fotos com documento</h2>
                      <div>
                        <Image.PreviewGroup>
                          {data?.cliente.urlsFotosDocumento.map((url, index) => (
                            <Image key={`doc-${index}`} width={200} src={`${baseURL}${url}`} />
                          ))}
                        </Image.PreviewGroup>
                      </div>
                    </div>
                    <div>
                      <h2 className={styles.tituloImages}>Selfie com documentos</h2>
                      <div>
                        <Image.PreviewGroup>
                          {data?.cliente.urlsFotosSelfieDocumento.map((url, index) => (
                            <Image key={`selfie-${index}`} width={200} src={`${baseURL}${url}`} />
                          ))}
                        </Image.PreviewGroup>
                      </div>
                    </div>
                    <div>
                      <h2 className={styles.tituloImages}>Comprovantes</h2>
                      <div>
                        <Image.PreviewGroup>
                          {data?.cliente.urlsFotosComprovante.map((url, index) => (
                            <Image key={`comprovante-${index}`} width={200} src={`${baseURL}${url}`} />
                          ))}
                        </Image.PreviewGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {step === 5 && (
              <>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Cliente'>
                    <Input value={data?.cliente.nome} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='CPF'>
                    <Input value={data?.cliente.cpf} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Tipo'>
                    <Input value={data?.tipoOperacao} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Valor do empréstimo'>
                    <Input value={data?.valorEmprestimo} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Juros'>
                    <Input value={data?.juros} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Total a pagar'>
                    <Input value={data?.valorTotal} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Número de parcelas'>
                    <Input value={data?.numeroParcelas} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Data do empréstimo'>
                    <Input value={formatarData(data?.dataEmprestimo)} />
                  </Form.Item>
                </Col>
              </>
            )}

            {step === 6 && (
              <>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Profissao'>
                    <Input value={data?.cliente.profissao} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Chave pix'>
                    <Input value={data?.cliente.chavePix} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Data de pagamento'>
                    <Input value={formatarData(data?.cliente.dataPagamento)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Data de vale'>
                    <Input value={formatarData(data?.cliente.dataVale)} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Nome favorecido'>
                    <Input value={data?.cliente.nomeFavorecido} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Nome do Banco'>
                    <Input value={banco.name} disabled />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className={styles.areaQrCode}>
                    <div>
                      <PIX
                        pixkey={detectarTipoChavePix(data?.cliente.chavePix) === 'Telefone' ? `+55${data?.cliente.chavePix}` : data?.cliente.chavePix}
                        merchant={data.cliente.nome.slice(0, 25)}
                        amount={data?.valorEmprestimo}
                        city='São josé do rio preto'
                        onLoad={(payload) => setCodePix(payload)}
                      />
                    </div>
                    <Button className={styles.button} onClick={copiarAreaTransferencia}>
                      Copiar código
                    </Button>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default StepsSeeClient;
