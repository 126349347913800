import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../../../services/apiClient';
import styles from './style.module.scss';
import { Spin, Form, Input, DatePicker, Row, Col, Modal, Upload, Select, Steps, Image, message, Button } from 'antd';
import InputMask from 'react-input-mask';
import { format } from 'date-fns';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import LoadingAmpulheta from '../../../Components/LoadingAmpulheta';
import { MdArrowBackIosNew } from 'react-icons/md';
const { TextArea } = Input;
import { useAuth } from '../../../contexts/AuthContext';
import CurrencyInput from 'react-currency-input-field';
import { addBusinessDays, addDays, addWeeks, addMonths } from 'date-fns';
import dayjs from 'dayjs';

const baseURL = 'https://credx-bucket.s3.amazonaws.com/';

const Operacao = () => {
  const { cpf: cpfParam } = useParams(); // Extrai o CPF dos parâmetros da URL
  const [cliente, setCliente] = useState({});
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  const [tipoOperacao, setTipoOperacao] = useState('');
  const [operador, setOperador] = useState();
  const [juros, setJuros] = useState('');
  const [dataEmprestimo, setDataEmprestimo] = useState();
  const [taxa, setTaxa] = useState('');
  const [prazo, setPrazo] = useState('');
  const [valor, setValor] = useState('');
  const [dataVencimento, setDataVencimento] = useState();
  const [valorTotal, setValorTotal] = useState('');

  const [colaboradores, setColaboradores] = useState([]);

  const [valorJurosMensal, setValorJurosMensal] = useState('');

  const [valorParcela, setValorParcela] = useState('');
  const [bancos, setBancos] = useState([]);

  const [fotosComprovante, setFotosComprovante] = useState([]);
  const [fotosSelfieDocumento, setFotosSelfieDocumento] = useState([]);
  const [fotosDocumento, setFotosDocumento] = useState([]);
  const [step, setStep] = useState(5);

  // Função para buscar dados do cliente
  const fetchClienteData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/find-client?cpf=${cpfParam}`); // Endpoint da API para buscar cliente por CPF

      if (response.data) {
        setCliente(response.data); // Armazena os dados do cliente no estado
        setFotosDocumento(response.data.urlsFotosDocumento.map((id) => `${baseURL}${id}`));
        setFotosComprovante(response.data.urlsFotosComprovante.map((id) => `${baseURL}${id}`));
        setFotosSelfieDocumento(response.data.urlsFotosSelfieDocumento.map((id) => `${baseURL}${id}`));
      }
    } catch (error) {
      console.error('Erro ao buscar dados do cliente:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cpfParam) {
      fetchClienteData();
    }
  }, [cpfParam]);

  const formatDate = (date) => {
    if (!date) return '';

    return format(date, 'dd/MM/yyyy');
  };

  const formatarCPF = (cpf) => {
    return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatarData = (dataString) => {
    if (!dataString) return '';

    const data = new Date(dataString);
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(data);
  };

  const [modalVisible, setModalVisible] = useState(false);
  const handleConfirmCadastro = () => {
    setModalVisible(true);
  };

  const handleChangeStep = () => {
    if (step < 5) {
      setStep(step + 1);
    } else {
      handleFormSubmit();
    }
  };

  const handleChangePrevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const carregarBancos = async () => {
    try {
      const resposta = await axios.get('https://brasilapi.com.br/api/banks/v1');
      setBancos(resposta.data);
    } catch (erro) {
      console.log('erro', erro.message);
      console.error('Não foi possível carregar os bancos:', erro);
    }
  };

  useEffect(() => {
    carregarBancos();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/user-all`);
      setColaboradores(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dataEmprestimo]);

  const handleFormSubmit = async () => {
    setLoading(true);

    const dadosOperacao = {
      etapa: 'analise',
      clienteId: cliente.id,
      tipoOperacao,
      valorEmprestimo: valor,
      prazo,
      dataEmprestimo: dataEmprestimo && dayjs(dataEmprestimo).format('YYYY-MM-DD'),
      proximaParcela: dataVencimento && dayjs(dataVencimento).format('YYYY-MM-DD'),
      valorTotal,
      numeroParcelas: prazo,
      juros,
      statusPagamento: 'Não pago',
      verificarPagamento: true,
      numeroSocios: 4,
      usuarioId: operador ? operador : user.id,
      anotacoes: null,
    };

    try {
      const response = await api.post('/operation', dadosOperacao);
      message.success('Operação cadastrada com sucesso!');
      navigate(`/`);
    } catch (error) {
      console.error('Erro ao cadastrar operação:', error);
      message.error('Erro ao cadastrar operação!');
    }

    setLoading(false);
  };

  const calcularValorTotal = (valor, taxa) => {
    const valorPrincipal = parseFloat(valor.replace(/\./g, '').replace(',', '.')); // muydar para cap[ital]
    const taxaNumber = Number(taxa);
    let jurosAcumulados;

    switch (tipoOperacao) {
      case 'diario':
        jurosAcumulados = valorPrincipal * (taxaNumber / 100);
        break;
      case 'semanal':
        jurosAcumulados = valorPrincipal * (taxaNumber / 100);
        break;
      case 'quinzenal':
        jurosAcumulados = valorPrincipal * (taxaNumber / 100);
        break;
      case 'mensal':
        jurosAcumulados = valorPrincipal * (taxaNumber / 100);
        break;

      default:
        jurosAcumulados = 0;
    }

    return valorPrincipal + jurosAcumulados;
  };

  useEffect(() => {
    if (valor && juros && prazo && tipoOperacao) {
      const total = calcularValorTotal(valor, juros);
      setValorTotal(total.toFixed(2));
    } else if (tipoOperacao === 'mensal' && valor && juros) {
      const total = calcularValorTotal(valor, juros);

      setValorTotal(total.toFixed(2));
    }
  }, [valor, juros, prazo, tipoOperacao]);

  useEffect(() => {
    if (prazo && valorTotal && !isNaN(prazo) && !isNaN(valorTotal) && prazo > 0) {
      const valorParcelaCalculado = valorTotal / prazo;
      setValorParcela(valorParcelaCalculado.toFixed(2)); // Atualiza o estado com o valor calculado
    } else if (tipoOperacao === 'mensal' && valor && juros) {
      setValorJurosMensal(valor * (juros / 100));
    } else {
      setValorParcela('');
    }
  }, [valorTotal, prazo, valor]);

  useEffect(() => {
    calcularDataPrimeiraParcela();
  }, [dataEmprestimo, tipoOperacao]);

  function calcularDataPrimeiraParcela() {
    const dataEmprestimoDate = dataEmprestimo ? new Date(dataEmprestimo.format('YYYY-MM-DD')) : null;

    if (!dataEmprestimoDate) {
      console.log('Data de empréstimo inválida');
      return;
    }

    let dataVencimentoCalculada;

    switch (tipoOperacao) {
      case 'diario':
        dataVencimentoCalculada = addBusinessDays(dataEmprestimoDate, 2);
        break;
      case 'semanal':
        dataVencimentoCalculada = addDays(dataEmprestimoDate, 8);
        break;
      case 'quinzenal':
        dataVencimentoCalculada = addDays(dataEmprestimoDate, 16);
        break;
      case 'mensal':
        const diaEmprestimo = dataEmprestimoDate.getDate();
        const proximoMes = dataEmprestimoDate.getMonth() + 1;
        const anoEmprestimo = dataEmprestimoDate.getFullYear();

        // Define o próximo mês e verifica se a data existe no próximo mês
        dataVencimentoCalculada = new Date(anoEmprestimo, proximoMes, diaEmprestimo + 1);

        // Se a data calculada for no mês seguinte e o dia não for o mesmo (por exemplo, 31 para 30), ajusta para o último dia do mês
        if (dataVencimentoCalculada.getMonth() !== proximoMes % 12) {
          // Ajusta para o último dia do mês anterior
          dataVencimentoCalculada = new Date(anoEmprestimo, proximoMes + 1, 0);
        }
        break;
      default:
        console.log('Tipo de operação inválido');
        return;
    }

    // Atualizar o estado com a nova data de vencimento
    // Convertendo para momento para manter a consistência com o seu uso de componentes DatePicker
    setDataVencimento(moment(dataVencimentoCalculada));
  }

  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerHeaderForm}>
        {step === 0 ? (
          <></>
        ) : (
          <button className={styles.arrowPrev} onClick={handleChangePrevStep}>
            <MdArrowBackIosNew />
          </button>
        )}
        <div className={styles.containerHeaderText}>
          <h1>{cliente.nome}</h1>
          <p>Cadastro</p>
        </div>
      </div>
      {step != 0 && (
        <Steps
          size='small'
          direction='horizontal'
          current={step}
          percent={60}
          className={styles.stepsContainer}
          responsive={false}
          items={[
            {
              title: 'Pessoa',
            },
            {
              title: 'Endereço',
            },
            {
              title: 'Profissão',
            },
            {
              title: 'Observação',
            },
            { title: 'Documentos' },
            { title: 'Empréstimos' },
          ]}
        />
      )}
      {loading ? (
        <LoadingAmpulheta />
      ) : (
        <>
          <Form layout='vertical' onFinish={handleFormSubmit}>
            <Row gutter={[16, 16]}>
              {step === 0 ? (
                <>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='CPF' className={styles.formItem}>
                      <div className={styles.inputGroup}>
                        <InputMask mask='999.999.999-99' value={cliente.cpf} disables>
                          {(inputProps) => <Input {...inputProps} />}
                        </InputMask>
                      </div>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='RG' className={styles.formItem}>
                      <div className={styles.inputGroup}>
                        <InputMask mask='99.999.999-9' value={cliente.rg} disabled>
                          {(inputProps) => <Input {...inputProps} />}
                        </InputMask>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label='Nome' className={styles.formItem}>
                      <Input placeholder='Digite o nome' type='text' value={cliente.nome} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label='Apelido' className={styles.formItem}>
                      <Input placeholder='Digite o apelido' value={cliente.apelido} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Data de nascimento' className={styles.formItem}>
                      <Input placeholder='Nascimento' value={formatDate(cliente.dataNascimento)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item name='celular' label='Celular' className={styles.formItem}>
                      <Input placeholder='Digite o celular' value={cliente.celular ? 'celular' : 'testessss'} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item name='telefone' label='Telefone referência' className={styles.formItem}>
                      <Input placeholder='Residencial' value={cliente.telefoneResidencial} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item name='referencia' label='Referencia' className={styles.formItem}>
                      <Input value={cliente.nomeReferencia} />
                    </Form.Item>
                  </Col>
                </>
              ) : step === 1 ? (
                <>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label='CEP - Residencial' className={styles.formItem}>
                      <InputMask mask='99999-999' value={cliente.cepResidencial}>
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Logradouro' className={styles.formItem}>
                      <Input value={cliente.logradouroResidencial} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Bairro' className={styles.formItem}>
                      <Input value={cliente.bairroResidencial} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={16} lg={16}>
                    <Form.Item label='Cidade' className={styles.formItem}>
                      <Input value={cliente.cidadeResidencial} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4}>
                    <Form.Item label='Estado' className={styles.formItem}>
                      <Input value={cliente.estadoResidencial} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={4}>
                    <Form.Item label='Número' className={styles.formItem}>
                      <Input value={cliente.numeroResidencial} />
                    </Form.Item>
                  </Col>

                  <hr />

                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label='CEP - Comercial' className={styles.formItem}>
                      <InputMask mask='99999-999' value={cliente.cepComercial}>
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Logradouro' className={styles.formItem}>
                      <Input value={cliente.logradouroComercial} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Bairro' className={styles.formItem}>
                      <Input value={cliente.bairroComercial} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={16} lg={16}>
                    <Form.Item label='Cidade' className={styles.formItem}>
                      <Input value={cliente.cidadeComercial} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4}>
                    <Form.Item label='Estado' className={styles.formItem}>
                      <Input value={cliente.estadoComercial} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={4}>
                    <Form.Item label='Número' className={styles.formItem}>
                      <Input value={cliente.numeroComercial} />
                    </Form.Item>
                  </Col>
                </>
              ) : step === 2 ? (
                <>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Profissao' className={styles.formItem}>
                      <Input value={cliente.profissao} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Chave pix' className={styles.formItem}>
                      <Input value={cliente.chavePix} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Data de pagamento' className={styles.formItem}>
                      <Input placeholder='Nascimento' value={formatDate(cliente.dataPagamento)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Data de vale' className={styles.formItem}>
                      <Input placeholder='Nascimento' value={formatDate(cliente.dataVale)} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Nome favorecido' className={styles.formItem}>
                      <Input value={cliente.nomeFavorecido} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label='Banco' className={styles.formItem}>
                      <Select showSearch placeholder='Selecione um banco' value={cliente.bancoSelecionado} filterOption={(input, option) => option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {bancos &&
                          bancos.map((banco) => (
                            <Select.Option key={banco.code} value={banco.code ? banco.code.toString() : ''} disabled>
                              {banco.fullName}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              ) : step === 3 ? (
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label='Observação' className={styles.formItem}>
                      <TextArea value={cliente.observacao} rows={4} placeholder='Observações sobre o cliente' />
                    </Form.Item>
                  </Col>
                </Row>
              ) : step === 4 ? (
                <div className={styles.containerFotos}>
                  <div>
                    <h2 className={styles.tituloImages}>Fotos com documento</h2>
                    <div>
                      <Image.PreviewGroup
                        preview={{
                          onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                        }}
                      >
                        {fotosDocumento.map((foto, index) => (
                          <Image key={`doc-${index}`} width={200} src={foto} />
                        ))}
                      </Image.PreviewGroup>
                    </div>
                  </div>
                  <div>
                    <h2 className={styles.tituloImages}>Selfie com documentos</h2>
                    <div>
                      <Image.PreviewGroup
                        preview={{
                          onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                        }}
                      >
                        {fotosSelfieDocumento.map((foto, index) => (
                          <Image key={`doc-${index}`} width={200} src={foto} />
                        ))}
                      </Image.PreviewGroup>
                    </div>
                  </div>
                  <div>
                    <h2 className={styles.tituloImages}>Selfie com documentos</h2>
                    <div>
                      <Image.PreviewGroup
                        preview={{
                          onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                        }}
                      >
                        {fotosComprovante.map((foto, index) => (
                          <Image key={`doc-${index}`} width={200} src={foto} />
                        ))}
                      </Image.PreviewGroup>
                    </div>
                  </div>
                </div>
              ) : step === 5 ? (
                <>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label='Tipo de operação' className={styles.formItem}>
                      <Select placeholder='Selecionar' value={tipoOperacao} onChange={setTipoOperacao}>
                        <Option value='diario'>Diário</Option>
                        <Option value='semanal'>Semanal</Option>
                        <Option value='mensal'>Mensal</Option>
                        <Option value='quinzenal'>Quinzenal</Option>
                        {/* <Option value='avulso'>Avulso</Option> */}
                        <Option value='mensal_parcelado'>Mensal parcelado</Option>
                        <Option value='mensal_parcelado_fixo'>Mensal parcelado fixo</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {user.tipoUsuario === 0 && (
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item label='Selecionar operador' className={styles.formItem}>
                        <Select placeholder='Selecionar' value={operador} onChange={setOperador}>
                          {colaboradores
                            .filter((colaborador) => colaborador.tipoUsuario === '3')
                            .map((value) => (
                              <Option key={value.id} value={value.id}>
                                {value.nome}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item label='Juros (%)' className={styles.formItem}>
                      <Input
                        placeholder='Digite os juros'
                        onChange={(e) => {
                          const valorNumerico = e.target.value.replace(/[^\d.]/g, ''); // Permite dígitos e ponto
                          setJuros(valorNumerico);
                        }}
                        value={juros} // Assumindo que você tem um estado `juros` para controlar o valor
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item label='Data do empréstimo' className={styles.formItem}>
                      <DatePicker format='DD/MM/YYYY' value={dataEmprestimo} onChange={(date) => setDataEmprestimo(date)} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item label='Valor' className={styles.formItem}>
                      <CurrencyInput
                        id='valor-input'
                        name='valor'
                        className={styles.valueInput}
                        placeholder='Digite o valor'
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        decimalsLimit={2}
                        onValueChange={(value) => setValor(value)}
                        prefix='R$ '
                      />
                    </Form.Item>
                  </Col>
                  {tipoOperacao != 'mensal' ? (
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <Form.Item label='Prazo ou N parcelas' className={styles.formItem}>
                        <Input placeholder='Digite o prazo' onChange={(e) => setPrazo(e.target.value)} />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}

                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item label='Data Primeira parcela' className={styles.formItem}>
                      <DatePicker format='DD/MM/YYYY' value={dataVencimento} onChange={(date) => setDataVencimento(date)} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item label='Valor Total' className={styles.formItem}>
                      <CurrencyInput id='valor-total-input' name='valorTotal' className={styles.valueInput} placeholder='Total' intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} decimalsLimit={2} value={valorTotal} disabled={true} prefix='R$ ' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item label={tipoOperacao != 'mensal' ? 'Valor da Parcela' : 'Valor Juros Mensal'} className={styles.formItem}>
                      <CurrencyInput
                        id='valor-parcela-input'
                        name='valorParcela'
                        className={styles.valueInput}
                        placeholder='Parcela'
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        decimalsLimit={2}
                        value={tipoOperacao != 'mensal' ? valorParcela : valorJurosMensal}
                        disabled={true}
                        prefix='R$ '
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </Form>

          <button className={styles.buttonCadastrarOp} onClick={step === 5 ? handleConfirmCadastro : handleChangeStep}>
            {loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                      color: '#fff',
                    }}
                    spin
                  />
                }
              />
            ) : step === 5 ? (
              'Finalizar'
            ) : (
              'Próximo'
            )}
          </button>
        </>
      )}
      <Modal
        width={900}
        title={<h2>Revisão de contrato</h2>}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key='generate' type='primary' onClick={handleFormSubmit}>
            {loading ? <LoadingOutlined /> : 'Gerar'}
          </Button>,
        ]}
      >
        <Form layout='vertical'>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item label='Nome'>
                <Input defaultValue={cliente.nome || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Apelido'>
                <Input defaultValue={cliente.apelido || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Data de nascimento'>
                <Input defaultValue={formatarData(cliente.dataNascimento) || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='RG'>
                <Input defaultValue={cliente.rg || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='CPF'>
                <Input defaultValue={formatarCPF(cliente.cpf) || 'Não informado'} disabled />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Celular'>
                <Input defaultValue={cliente.celular || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='CEP'>
                <Input defaultValue={cliente.cepResidencial || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item label='Logradouro'>
                <Input defaultValue={cliente.logradouroResidencial || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item label='Bairro'>
                <Input defaultValue={cliente.bairroResidencial || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='N°'>
                <Input defaultValue={cliente.numeroResidencial || 'Não informado'} disabled />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Cidade'>
                <Input defaultValue={cliente.cidadeResidencial || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Estado'>
                <Input defaultValue={cliente.estadoResidencial || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <br />
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item label='Tipo Operação'>
                <Input defaultValue={tipoOperacao || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Juros'>
                <Input defaultValue={`${juros}%` || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Data Emprestimo'>
                <Input defaultValue={formatarData(dataEmprestimo) || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Data Primeira Parcela'>
                <Input defaultValue={formatarData(dataVencimento) || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Valor'>
                <Input defaultValue={formatarValor(valor) || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Valor Total'>
                <Input defaultValue={formatarValor(valorTotal) || 'Não informado'} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Valor Mensal'>
                <Input defaultValue={formatarValor(valorJurosMensal) || 'Não informado'} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default Operacao;
