import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import axios from 'axios';
import { Form, Input, Select, Steps, message, Row, Col, Upload, Modal } from 'antd';

import InputMask from 'react-input-mask';
import { Spin } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { api } from '../../../services/apiClient';
import { useAuth } from '../../../contexts/AuthContext';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom'; // Importe useNavigate
import LoadingAmpulheta from '../../../Components/LoadingAmpulheta';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

const { Option } = Select; // Importação corrigida
const { TextArea } = Input;

import { format } from 'date-fns';

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Client = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [nome, setNome] = useState('');
  const [apelido, setApelido] = useState('');
  const [rg, setRg] = useState('');
  const [cpf, setCpf] = useState('');
  const [nascimento, setNascimento] = useState();

  const [enderecoResidencial, setEnderecoResidencial] = useState('');
  const [enderecoComercial, setEnderecoComercial] = useState('');
  const [celular, setCelular] = useState('');
  const [telefoneResidencial, setTelefoneResidencial] = useState('');

  const [step, setStep] = useState(0);

  const [loading, setLoading] = useState(false);

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await api.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.data.Key;
    } catch (error) {
      console.error('Erro ao fazer upload de arquivo', error);
      throw error;
    }
  };

  const formatDate = (date) => {
    if (!date) return '';

    return format(date, 'yyyy-MM-dd');
  };

  const handleFormSubmit = async () => {
    setLoading(true);

    try {
      // Exemplo de como obter URLs após uploads
      const urlsFotosDocumento = await Promise.all(rgFileList.map((file) => uploadFile(file.originFileObj)));
      const urlsFotosSelfieDocumento = await Promise.all(selfieFileList.map((file) => uploadFile(file.originFileObj)));
      const urlsFotosComprovante = await Promise.all(residenceProofFileList.map((file) => uploadFile(file.originFileObj)));

      // Preparar dados para envio, incluindo os novos campos
      const dadosCliente = {
        nome,
        apelido,
        rg,
        cpf,
        dataNascimento: nascimento ? formatDate(nascimento) : null, // Certifique-se de que 'nascimento' não está vazio
        enderecoResidencial,
        enderecoComercial,
        celular,
        telefoneResidencial,
        urlsFotosDocumento,
        urlsFotosSelfieDocumento,
        urlsFotosComprovante,
        usuarioId: user.id,
        // Novos campos
        cepResidencial,
        logradouroResidencial,
        bairroResidencial,
        cidadeResidencial,
        estadoResidencial,
        numeroResidencial,
        cepComercial,
        logradouroComercial,
        bairroComercial,
        cidadeComercial,
        estadoComercial,
        numeroComercial,
        nomeFavorecido,
        bancoSelecionado,
        profissao,
        chavePix,
        dataPagamento: dataPagamento ? formatDate(dataPagamento) : null, // Formatação da data, se necessário
        dataVale: dataVale ? formatDate(dataVale) : null, // Formatação da data, se necessário
        nomeReferencia,
        observacao,
        // dataVale não está sendo enviado; verifique se é necessário
      };

      const response = await api.post('/cliente', dadosCliente);
      message.success('Cliente cadastrado com sucesso!');
      // Considerar usar um método de navegação do React Router em vez de window.location.href, se aplicável
      navigate(`/cadastro/contratos/${cpf}`);
    } catch (error) {
      console.error(error.message);
      message.error('Erro ao cadastrar cliente!');
    }

    setLoading(false);
  };

  const handleChangeStep = () => {
    step === 0 ? setStep(1) : step === 1 ? setStep(2) : step === 2 ? setStep(3) : step === 3 ? setStep(4) : step === 4 ? handleFormSubmit() : null;
  };
  const handleChangePrevStep = () => {
    step === 1 ? setStep(0) : step === 2 ? setStep(1) : step === 3 ? setStep(2) : step === 3 ? setStep(3) : step === 4 ? setStep(3) : null;
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [rgFileList, setRgFileList] = useState([]);
  const [selfieFileList, setSelfieFileList] = useState([]);
  const [residenceProofFileList, setResidenceProofFileList] = useState([]);

  const [observacao, setObservacao] = useState('');

  const [cepResidencial, setCepResidencial] = useState(''); // Estado para armazenar o CEP
  const [logradouroResidencial, setLogradouroResidencial] = useState(''); // Estado para o logradouro
  const [bairroResidencial, setBairroResidencial] = useState(''); // Estado para o bairro
  const [cidadeResidencial, setCidadeResidencial] = useState(''); // Estado para a cidade
  const [estadoResidencial, setEstadoResidencial] = useState(''); // Estado para o estado
  const [numeroResidencial, setNumeroResidencial] = useState('');

  const [cepComercial, setCepComercial] = useState(''); // Estado para armazenar o CEP
  const [logradouroComercial, setLogradouroComercial] = useState(''); // Estado para o logradouro
  const [bairroComercial, setBairroComercial] = useState(''); // Estado para o bairro
  const [cidadeComercial, setCidadeComercial] = useState(''); // Estado para a cidade
  const [estadoComercial, setEstadoComercial] = useState(''); // Estado para o estado
  const [numeroComercial, setNumeroComercial] = useState('');

  const [nomeFavorecido, setNomeFavorecido] = useState('');
  const [bancoSelecionado, setBancoSelecionado] = useState('');
  const [bancos, setBancos] = useState([]);
  const [profissao, setProfissao] = useState('');
  const [chavePix, setChavePix] = useState('');
  const [dataVale, setDataVale] = useState(new Date());
  const [dataPagamento, setDataPagamento] = useState(new Date());

  const [nomeReferencia, setNomeReferencia] = useState('');

  useEffect(() => {
    const carregarBancos = async () => {
      try {
        const resposta = await axios.get('https://brasilapi.com.br/api/banks/v1');
        setBancos(resposta.data);
      } catch (erro) {
        console.error('Não foi possível carregar os bancos:', erro);
      }
    };

    carregarBancos();
  }, []);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  // Função para limpar e limitar o RG (ajuste o limite conforme necessário)
  const handleRgValueChange = (e) => {
    const valor = e.target.value.replace(/\D/g, ''); // Remove tudo que não for dígito
    setRg(valor.slice(0, 9)); // Exemplo: Limita a 9 caracteres para RG
  };

  // Verifica se o valor contém apenas letras, espaços e acentos
  const handleNomeChange = (e) => {
    if (/^[A-Za-zÀ-ú ]+$/.test(e.target.value) || e.target.value === '') {
      setNome(e.target.value);
    }
  };

  // Verifica se o valor contém apenas letras, espaços e acentos
  const handleApelidoChange = (e) => {
    if (/^[A-Za-zÀ-ú ]+$/.test(e.target.value) || e.target.value === '') {
      setApelido(e.target.value);
    }
  };

  const handleRgChange = ({ fileList }) => setRgFileList(fileList);
  const handleSelfieChange = ({ fileList }) => setSelfieFileList(fileList);
  const handleResidenceProofChange = ({ fileList }) => setResidenceProofFileList(fileList);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type='button'
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  // Função para lidar com a mudança no CEP residencial
  const handleCepChange = async (e) => {
    const valor = e.target.value.replace(/\D/g, ''); // Remove tudo que não for dígito
    setCepResidencial(valor);
    if (valor.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${valor}/json/`);
        setLogradouroResidencial(response.data.logradouro);
        setBairroResidencial(response.data.bairro);
        setCidadeResidencial(response.data.localidade);
        setEstadoResidencial(response.data.uf);
      } catch (error) {
        console.error('Erro ao buscar o CEP', error);
      }
    }
  };

  const handleCepComercialChange = async (e) => {
    const valor = e.target.value.replace(/\D/g, ''); // Remove tudo que não for dígito
    setCepComercial(valor);
    if (valor.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${valor}/json/`);
        setLogradouroComercial(response.data.logradouro);
        setBairroComercial(response.data.bairro);
        setCidadeComercial(response.data.localidade);
        setEstadoComercial(response.data.uf);
        // Aqui você pode atualizar o estado do endereço residencial ou comercial com os dados da API
        // Por exemplo: setEnderecoResidencial(`${response.data.logradouro}, ${response.data.bairro}`);
      } catch (error) {
        console.error('Erro ao buscar o CEP', error);
      }
    }
  };

  const [cpfExists, setCpfExists] = useState(null);
  const [loadingPesquisaCpf, setLoadingPesquisaCpf] = useState(false);

  const checkCpfExists = async (cpf) => {
    try {
      const response = await api.get(`/find-client?cpf=${cpf}`);
      return response;
    } catch (error) {
      return null;
    }
  };

  const handleCpfChange = async (e) => {
    const valor = e.target.value.replace(/\D/g, ''); // Remove tudo que não for dígito
    setCpf(valor.slice(0, 11)); // Limita a 11 caracteres
    if (valor.length === 11) {
      setLoadingPesquisaCpf(true);
      const check = await checkCpfExists(valor);
      if (check) {
        setLoadingPesquisaCpf(false);

        setCpfExists(true);
        navigate(`/cadastro/contratos/${valor}`);
      } else {
        setLoadingPesquisaCpf(false);

        setCpfExists(false);
      }
      setLoadingPesquisaCpf(false);
    }
  };

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            // href: '',
            title: (
              <>
                <span>Operações</span>
              </>
            ),
          },
          {
            title: 'Novo Cadastro',
          },
        ]}
      />
      <div className={styles.containerPesquisa}>
        <div className={styles.containerHeaderForm}>
          {step === 0 ? (
            <></>
          ) : (
            <button className={styles.arrowPrev} onClick={handleChangePrevStep}>
              <MdArrowBackIosNew />
            </button>
          )}
          <div className={styles.containerHeaderText}>
            <h1>Cadastrar clientes</h1>
            <h3>Preencha os dados abaixo para completar o cadastro de seu cliente</h3>
          </div>
        </div>
        {step != 0 && (
          <Steps
            size='small'
            direction='horizontal'
            current={step}
            percent={60}
            className={styles.stepsContainer}
            responsive={false}
            items={[
              {
                title: 'Pessoa',
              },
              {
                title: 'Endereço',
              },
              {
                title: 'Profissão',
              },
              {
                title: 'Observação',
              },
              { title: 'Documentos' },
              { title: 'Empréstimos' },
            ]}
          />
        )}
        <Form layout='vertical' onFinish={handleFormSubmit} className={styles.containerValores}>
          <Row gutter={[16, 16]}>
            {step === 0 ? (
              <>
                <Col xs={24} sm={24} md={cpfExists === false ? 12 : 24} lg={cpfExists === false ? 12 : 24}>
                  <Form.Item label='CPF' className={styles.formItem}>
                    <div className={styles.inputGroup}>
                      <InputMask mask='999.999.999-99' value={cpf} onChange={handleCpfChange}>
                        {(inputProps) => <Input {...inputProps} />}
                      </InputMask>
                    </div>
                  </Form.Item>
                </Col>

                {cpfExists === false && (
                  <>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item label='RG' className={styles.formItem}>
                        <div className={styles.inputGroup}>
                          <InputMask mask='99.999.999-9' value={rg} onChange={handleRgValueChange}>
                            {(inputProps) => <Input {...inputProps} />}
                          </InputMask>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item label='Nome' className={styles.formItem}>
                        <Input placeholder='Digite o nome' value={nome} onChange={handleNomeChange} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <Form.Item label='Apelido' className={styles.formItem}>
                        <Input placeholder='Digite o apelido' value={apelido} onChange={handleApelidoChange} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item label='Data de nascimento' className={styles.formItem}>
                        <DatePicker selected={nascimento} style={{ width: '100%' }} locale='pt-BR' onChange={(date) => setNascimento(date)} dateFormat='dd/MM/yyyy' className={styles.customDatePickerStyle} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item name='celular' label='Celular' className={styles.formItem}>
                        <InputMask mask='(99) 99999-9999' value={celular} onChange={(e) => setCelular(e.target.value)}>
                          {(inputProps) => <Input {...inputProps} style={{ width: '100%' }} />}
                        </InputMask>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item name='telefone' label='Telefone referência' className={styles.formItem}>
                        <InputMask mask='(99) 99999-9999' value={telefoneResidencial} onChange={(e) => setTelefoneResidencial(e.target.value)}>
                          {(inputProps) => <Input {...inputProps} style={{ width: '100%' }} />}
                        </InputMask>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item name='referencia' label='Referencia' className={styles.formItem}>
                        <Input placeholder='Digite quem é a referencia' onChange={(e) => setNomeReferencia(e.target.value)} />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {cpfExists === true && <h1 style={{ textAlign: 'center', width: '100%' }}>O CPF informado já existe em nossa base de dados</h1>}
                {loadingPesquisaCpf && <LoadingAmpulheta />}
              </>
            ) : step === 1 ? (
              <>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='CEP - Residencial' className={styles.formItem}>
                    <InputMask mask='99999-999' value={cepResidencial} onChange={handleCepChange}>
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Logradouro' className={styles.formItem}>
                    <Input value={logradouroResidencial} onChange={(e) => setLogradouroResidencial(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Bairro' className={styles.formItem}>
                    <Input value={bairroResidencial} onChange={(e) => setBairroResidencial(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={16} lg={16}>
                  <Form.Item label='Cidade' className={styles.formItem}>
                    <Input value={cidadeResidencial} onChange={(e) => setCidadeResidencial(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <Form.Item label='Estado' className={styles.formItem}>
                    <Input value={estadoResidencial} onChange={(e) => setEstadoResidencial(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={4} lg={4}>
                  <Form.Item label='Número' className={styles.formItem}>
                    <Input value={numeroResidencial} onChange={(e) => setNumeroResidencial(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='CEP - Comercial' className={styles.formItem}>
                    <InputMask mask='99999-999' value={cepComercial} onChange={handleCepComercialChange}>
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Logradouro' className={styles.formItem}>
                    <Input value={logradouroComercial} onChange={(e) => setLogradouroComercial(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Bairro' className={styles.formItem}>
                    <Input value={bairroComercial} onChange={(e) => setBairroComercial(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={16} lg={16}>
                  <Form.Item label='Cidade' className={styles.formItem}>
                    <Input value={cidadeComercial} onChange={(e) => setCidadeComercial(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <Form.Item label='Estado' className={styles.formItem}>
                    <Input value={estadoComercial} onChange={(e) => setEstadoComercial(e.target.value)} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={4} lg={4}>
                  <Form.Item label='Número' className={styles.formItem}>
                    <Input value={numeroComercial} onChange={(e) => setNumeroComercial(e.target.value)} />
                  </Form.Item>
                </Col>
              </>
            ) : step === 2 ? (
              <>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Profissão' className={styles.formItem}>
                    <Input value={profissao} onChange={(e) => setProfissao(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Chave PIX' className={styles.formItem}>
                    <Input value={chavePix} onChange={(e) => setChavePix(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Data de pagamento' className={styles.formItem}>
                    <DatePicker selected={dataPagamento} locale='pt-BR' onChange={(date) => setDataPagamento(date)} dateFormat='dd/MM/yyyy' className={styles.customDatePickerStyle} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Data do vale' className={styles.formItem}>
                    <DatePicker selected={dataVale} locale='pt-BR' onChange={(date) => setDataVale(date)} dateFormat='dd/MM/yyyy' className={styles.customDatePickerStyle} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Nome favorecido' className={styles.formItem}>
                    <Input value={nomeFavorecido} onChange={(e) => setNomeFavorecido(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label='Banco' className={styles.formItem}>
                    <Select
                      showSearch
                      placeholder='Selecione um banco'
                      value={bancoSelecionado}
                      onChange={(value) => setBancoSelecionado(value)}
                      filterOption={(input, option) => option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {bancos &&
                        bancos.map((banco) => (
                          <Select.Option key={banco.code} value={banco.code ? banco.code.toString() : ''}>
                            {banco.fullName}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            ) : step === 3 ? (
              <>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='Observação'>
                    <TextArea value={observacao} onChange={(e) => setObservacao(e.target.value)} rows={4} placeholder='Observações sobre o cliente' />
                  </Form.Item>
                </Col>
              </>
            ) : step === 4 ? (
              <div className={styles.containerFotos}>
                <h1>Adicione uma foto ou mais do RG, CPF ou CNH</h1>
                <Upload action='https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188' listType='picture-card' fileList={rgFileList} onPreview={handlePreview} onChange={handleRgChange}>
                  {rgFileList.length >= 8 ? null : uploadButton}
                </Upload>
                {/* ... */}
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>

                <h1>Adicione uma Selfie sua segurando o mesmo documento</h1>
                <Upload action='https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188' listType='picture-card' fileList={selfieFileList} onPreview={handlePreview} onChange={handleSelfieChange}>
                  {selfieFileList.length >= 8 ? null : uploadButton}
                </Upload>
                {/* ... */}
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>

                <h1>Adicione uma foto do comprovante de residência</h1>
                <Upload action='https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188' listType='picture-card' fileList={residenceProofFileList} onPreview={handlePreview} onChange={handleResidenceProofChange}>
                  {residenceProofFileList.length >= 8 ? null : uploadButton}
                </Upload>
                {/* ... */}
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            ) : (
              <></>
            )}
          </Row>
        </Form>
        {cpfExists === false && (
          <button className={styles.buttonCadastrarOp} onClick={step === 4 ? handleFormSubmit : handleChangeStep}>
            {loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                      color: '#fff',
                    }}
                    spin
                  />
                }
              />
            ) : step === 4 ? (
              'Finalizar'
            ) : (
              'Próximo'
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default Client;
