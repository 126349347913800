import React from 'react';
import { Table, Tag, Button, Modal, Form, Col, Row, Select, message, Upload, Input, Divider, Switch } from 'antd';
import CurrencyInput from 'react-currency-input-field';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import styles from './style.module.scss';

const ModalEnviarMensagem = ({ modalMensagem, setModalMensagem, enviarMensagem, mensagem, setMensagem, telefone, setTelefone }) => {
  return (
    <>
      <Modal
        title='Enviar Mensagem'
        open={modalMensagem}
        onCancel={() => setModalMensagem(false)}
        footer={[
          <Button key='cancel' onClick={() => setModalMensagem(false)}>
            Cancelar
          </Button>,
          <Button key='submit' type='primary' onClick={enviarMensagem}>
            Enviar
          </Button>,
        ]}
      >
        <div style={{ marginBottom: '16px' }}>
          <label>Mensagem a ser enviada:</label>
          <Input.TextArea value={mensagem} onChange={(e) => setMensagem(e.target.value)} placeholder='Digite a mensagem aqui' rows={4} />
        </div>
        <div>
          <label>Número de telefone:</label>
          <Input value={telefone} onChange={(e) => setTelefone(e.target.value)} placeholder='Digite o número de telefone' />
        </div>
      </Modal>
    </>
  );
};

export default ModalEnviarMensagem;
