import React from 'react';
import { Table, Tag, Button, Modal, Form, Col, Row, Select, message, Upload, Input, Divider, Switch } from 'antd';
import CurrencyInput from 'react-currency-input-field';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import styles from './style.module.scss';

const ModalParcelasResumoOperacao = ({ isModalVerclienteOpen, setIsModalVerClienteOpen, modalVercliente, formatarComoMoeda, formatarData }) => {
 
  const getEtapaID = (etapa) => {
    return `etapa-${etapa}`; // Isso retornará algo como "etapa-inicio" baseado na etapa.
  };
 
  return (
    <>
      <Modal
        title='Parcelas'
        open={isModalVerclienteOpen}
        onOk={() => setIsModalVerClienteOpen(false)}
        onCancel={() => setIsModalVerClienteOpen(false)}
        width={900} // Ajuste conforme necessário
      >
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                <th>Status</th>
                <th>Número Parcela</th>
                <th>Valor Pago</th>
                <th>Valor Parcela</th>
                <th>Restante a Pagar</th>
                <th>Status Pagamento</th>
                <th>Confirmação Pagamento</th>
                <th>Data Vencimento</th>
                {/* <th>Comprovantes</th> */}
              </tr>
            </thead>
            <tbody>
              {modalVercliente && modalVercliente.length > 0 ? (
                modalVercliente.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <div className={styles[getEtapaID(row.status)]}>
                        <p>{row.status === 'Pago' ? 'Pago' : 'Aberto'}</p>
                      </div>
                    </td>
                    <td>{row.numeroParcela}</td>
                    <td>{formatarComoMoeda(row.valorPago)}</td>
                    <td>{formatarComoMoeda(row.valorParcela)}</td>
                    <td>{formatarComoMoeda(row.saldoRestante)}</td>
                    <td>{row.statusPagamentoAgente === 'Pago' ? <p className={styles.pagoValue}>Pago</p> : <p className={styles.naoPagoValue}>Não Pago</p>}</td>
                    <td>{row.confirmacaoAnalista ? <p className={styles.pagoValue}>Pago</p> : <p className={styles.naoPagoValue}>Não Pago</p>}</td>
                    <td>{formatarData(row.dataVencimento)}</td>
                    {/* <td>
                      <Button onClick={() => verComprovantes(row.comprovantesPagamento)}>Comprovantes</Button>
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='9'>Nenhuma parcela encontrada.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default ModalParcelasResumoOperacao;
