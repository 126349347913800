// src/utils/tools.js
import { message, Tag, Button } from 'antd';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { IoEyeSharp } from 'react-icons/io5';
import { FaWhatsapp } from 'react-icons/fa';
import { api } from '../services/apiClient'; // Importação do cliente de API

// Função para formatar valores como moeda brasileira
export const formatarComoMoeda = (valor) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(valor);
};

// Função para formatar datas em padrão brasileiro
export const formatarData = (dataString) => {
  const data = new Date(dataString);
  if (isNaN(data.getTime())) {
    console.error('Data inválida:', dataString);
    return 'Data inválida';
  }
  return new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    timeZone: 'UTC',
  }).format(data);
};

// Função para capitalizar nome do cliente
export const formatarNomeCliente = (nome) => {
  return nome
    ? nome
        .split(' ')
        .map((palavra) => palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase())
        .join(' ')
    : '';
};

// Função para formatar status da operação com estilo visual
export const renderizarStatusTag = (status, dataVencimento) => {
  const today = new Date();
  const dueDate = new Date(dataVencimento);

  // Adiciona um dia na data de vencimento para tolerância
  dueDate.setDate(dueDate.getDate() + 1);

  today.setHours(0, 0, 0, 0);
  dueDate.setHours(0, 0, 0, 0);

  // Comparação correta para determinar se está atrasado
  const isAtrasada = today > dueDate && status !== 'pago';
  const displayStatus = isAtrasada ? 'atrasado' : status;

  const tagStyle = {
    // backgroundColor: isAtrasada ? '#FFD3D3' : status === 'pago' ? '#DDFFCD' : status === 'acordo' ? '#ADD8E6' :'#fff',
    backgroundColor: '#fff',
    color: isAtrasada ? '#F90000' : status === 'pago' ? '#007F00' : status === 'acordo' ? '#00008B': status === 'parcial' ? '#ff6400' : '#2EA2B9',
    padding: '4px 4px 4px 20px',
    borderRadius: '30px',
    border: 'none',
    // border: ` 1px solid ${isAtrasada ? '#FFD3D3' : status === 'pago' ? '#DDFFCD' : status === 'acordo' ? '#ADD8E6' :'#F99500'}`,
    width: '80px',
    textAlign: 'center',
    position: 'relative',
  };

  const circleStyle = {
    backgroundColor: isAtrasada ? '#F90000' : status === 'pago' ? '#007F00' : status === 'acordo' ? '#00008B' : status === 'parcial' ? '#ff6400' : '#2EA2B9',
    position: 'absolute',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  return (
    <Tag style={tagStyle}>
      <span style={circleStyle}></span>
      {displayStatus.toUpperCase()}
    </Tag>
  );
};

// Função para renderizar ações de pagamento, visualização e envio de mensagem
export const renderizarAcoes = (record, handleActionClick, veroperacao, handleSendMessage, styles) => (
  <div className={styles.rowButtonsTable}>
    <Button className={styles.buttonPagar} onClick={() => handleActionClick(record)} type='primary' size='small' disabled={record.status === 'pago'}>
      <MdOutlineAttachMoney />
    </Button>
    <Button className={styles.buttonPagar} onClick={() => veroperacao(record.operacao.id)} type='primary' size='small' disabled={record.status === 'pago'}>
      <IoEyeSharp />
    </Button>
    <Button className={styles.buttonPagar} onClick={() => handleSendMessage(record)} type='primary' size='small' disabled={record.status === 'pago'}>
      <FaWhatsapp />
    </Button>
  </div>
);

// Função para gerar a mensagem de pagamento formatada
export const gerarMensagemPagamento = (operacao) => {
  const nomeCliente = operacao?.operacao?.cliente?.nome || 'Cliente';
  const saldoRestante = formatarComoMoeda(operacao?.saldoRestante || 0);
  const dataVencimento = formatarData(operacao?.dataVencimento);

  return `Bom dia, ${nomeCliente}
segue sua parcela ${saldoRestante} para vencimento ${dataVencimento}

CHAVE PIX
CPF: 25604257850
NOME FAVORECIDO: Odair Colturato
BANCO: Santander

@Favor enviar o comprovante de pagamento 🧾`;
};

// Função para enviar mensagem via API Polaplon
export const enviarMensagemAPI = async ({ mensagem, telefone, tokenEnvio }) => {
  if (!mensagem || !telefone || !tokenEnvio) {
    message.warning('Preencha ambos os campos.');
    return;
  }

  const url = 'https://api.polaplon.com.br/api/messages/send';
  const payload = {
    number: `55${telefone}`,
    body: mensagem,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${tokenEnvio}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const data = await response.json();
      message.success('Mensagem enviada com sucesso');
      return data;
    } else {
      const errorData = await response.json();
      console.error('Erro ao enviar a mensagem:', errorData);
      throw new Error(errorData.message || 'Erro ao enviar a mensagem.');
    }
  } catch (error) {
    console.error('Erro na requisição:', error);
    throw error;
  }
};

export const handleUpload = async ({ file, onSuccess, onError, apiUrl = '/upload' }) => {
  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await api.post(apiUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    onSuccess(response.data.data.Key); // Retorna a chave do arquivo
  } catch (error) {
    console.error('Erro ao fazer upload:', error);
    onError(error); // Erro na função de callback
  }
};

export const filtrarDadosPorStatus = (dados, statusFiltro) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return dados.filter((item) => {
    const dueDate = new Date(item.dataVencimento);
    dueDate.setHours(0, 0, 0, 0);

    if (statusFiltro === 'pago') {
      return item.status === 'pago';
    } else if (statusFiltro === 'Aberta') {
      return dueDate.getTime() === today.getTime() && item.status === 'Aberta';
    } else if (statusFiltro === 'atrasada') {
      return dueDate < today && item.status !== 'pago';
    }
    return true; // Caso não haja filtro, retorna todos os dados
  });
};

export const aplicarFiltros = (dados, filtros) => {
  let dadosFiltrados = [...dados];

  // Verifica se o filtro de cliente está definido e não é nulo
  if (filtros.cliente) {
    dadosFiltrados = dadosFiltrados.filter((item) => item.operacao?.cliente?.id === filtros.cliente);
  }

  return dadosFiltrados;
};
