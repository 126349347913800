import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, message, Tabs } from 'antd';
import styles from './style.module.scss';
import { api } from '../../../services/apiClient';
import { useAuth } from '../../../contexts/AuthContext';
import ModalPix from '../../../Components/ModalPix';
import RowAprovacoes from '../../../Components/RowAprovacoes';
import StepsSeeClient from '../../../Components/StepsSeeClient';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { Empty } from 'antd';
import TableAprovado from '../../../Components/TableAprovado';

const { TabPane } = Tabs; // Importante para usar o componente TabPane

const Aprovado = () => {
  const { user } = useAuth();
  const [operacoes, setOperacoes] = useState([]);
  const [subOperacoes, setSubOperacoes] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [dadosTabela, setDadosTabela] = useState([]);

  const fetchOperacoes = async () => {
    try {
      const response = await api.get(`/operation-etapa/aprovado`);
      setOperacoes(response.data.operacoes);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchSubOperacoes = async () => {
    try {
      const response = await api.get(`/suboperation`);

      setSubOperacoes(response.data);
      setDadosTabela(response.data.operacoes); // Definir setDadosTabela aqui
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user && user.tipoUsuario <= 3) {
      fetchOperacoes();
      fetchSubOperacoes();
    } else {
      console.log('Sem acesso!');
    }
  }, []);

  const onChildUpdate = () => {
    fetchOperacoes();
  };

  const handleRecusar = async (operacaoId) => {
    try {
      const response = await api.put(`/operation/${operacaoId}`, { etapa: 'recusado' });
      fetchOperacoes();
      message.success('Operação atualizada com sucesso!');
    } catch (error) {
      console.log('error', error.message);
      message.error('Não foi possível atualizar operação!');
    }
  };

  const handleBlackList = async (operacaoId) => {
    try {
      const response = await api.put(`/operation/${operacaoId}`, { etapa: 'negada', textNegado: 'blacklist' });
      fetchOperacoes();
      message.success('Operação atualizada com sucesso!');
    } catch (error) {
      console.log('error', error.message);
      message.error('Não foi possível atualizar operação!');
    }
  };
  const handleAprovar = async (operacaoId) => {
    try {
      const response = await api.put(`/operation/${operacaoId}`, { etapa: 'aberto' });
      fetchOperacoes();
      setModalVisible(false);

      message.success('Operação atualizada com sucesso!');
    } catch (error) {
      console.log('error', error.message);
      message.error('Não foi possível atualizar operação!');
    }
  };

  const [initialStepModal, setInitialStepModal] = useState(0);

  const handleVisualizarCliente = (operacao) => {
    if (operacao.etapa) {
      setClienteSelecionado(operacao);
      const initialStep = operacao.etapa === 'analise' ? 0 : operacao.etapa === 'aprovado' ? 0 : 0;
      setInitialStepModal(initialStep);
      setModalVisible(true);
    } else {
      console.error('Operação inválida:', operacao);
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleAceitar = async (operacaoId) => {
    await atualizarParcelaParaPago(operacaoId);
    setModalVisible(false);
  };

  const atualizarParcelaParaPago = async (operacaoId) => {
    try {
      const response = await api.put(`/operation/${operacaoId}`, { etapa: 'aprovado' });
      fetchOperacoes();
      message.success('Operação atualizada com sucesso!');
    } catch (error) {
      console.log('error', error.message);
      message.error('Não foi possível atualizar operação!');
    }
  };

  
  const [modalPix, setModalPix] = useState(false);
  const [modalPixContent, setModalPixContent] = useState();
  const [urlPix, setUrlPix] = useState('');

  const openModalPix = (dados) => {
    setModalPixContent(dados);
    setModalPix(true);
  };


  const aprovarMandarMensagem = async (clienteSelecionado) => {
    
    const { cliente, subOperacoes, valorEmprestimo, juros, numeroParcelas, valorTotal } = clienteSelecionado;
    if(!cliente.celular || cliente.celular === ''){
      message.error('Cliente não tem telefone cadastrado!');
      
      return
    }
    await handleAprovar(clienteSelecionado.id);
  
    let mensagem = `Olá ${cliente.nome},\n\nSua operação de empréstimo foi aprovada com sucesso!\n\nDetalhes da Operação:\n`;
    mensagem += `Valor do Empréstimo: R$ ${valorEmprestimo}\n`;
    // mensagem += `Juros: ${juros}%\n`;
    mensagem += `Valor Total a Pagar: R$ ${valorTotal}\n`;
    mensagem += `Número de Parcelas: ${numeroParcelas}\n\n`;
  
    mensagem += `Parcelas:\n`;
  
    subOperacoes.forEach((parcela, index) => {
      const dataVencimento = new Date(parcela.dataVencimento).toLocaleDateString();
      mensagem += `Parcela ${index + 1}: R$ ${parcela.valorParcela} - Vencimento: ${dataVencimento}\n`;
    });
  
    await enviarMensagem(mensagem, cliente.celular, clienteSelecionado.usuario.tokenPolaplon);
  };
  const aprovar = async (clienteSelecionado) => {
    
    const { cliente, subOperacoes, valorEmprestimo, juros, numeroParcelas, valorTotal } = clienteSelecionado;
    if(!cliente.celular || cliente.celular === ''){
      message.error('Cliente não tem telefone cadastrado!');
      
      return
    }
    await handleAprovar(clienteSelecionado.id);
  
  };
  
  const enviarMensagem = async (mensagem, telefone, tokenEnvio) => {
    if (!mensagem || !telefone || !tokenEnvio) {
      message.warning('Preencha ambos os campos.');
      return;
    }

    const url = 'https://api.polaplon.com.br/api/messages/send';

    const payload = {
      number: `55${telefone}`,
      body: mensagem,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokenEnvio}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Mensagem enviada com sucesso:', data);
        // setModalMensagem(false);
        // setMensagem('');
        message.success('Mensagem enviada com sucesso');

        return data; // Retorna os dados recebidos da API
      } else {
        const errorData = await response.json();
        console.error('Erro ao enviar a mensagem:', errorData);
        throw new Error(errorData.message || 'Erro ao enviar a mensagem.');
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      throw error;
    }
  };


  return (
    <div className={styles.container}>
      <div>
        <Breadcrumb
          items={[
            {
              title: (
                <Link to='/'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                      fill='#757575'
                    />
                  </svg>
                </Link>
              ),
            },
            {
              // href: '',
              title: (
                <>
                  <span>Operações</span>
                </>
              ),
            },
            {
              title: 'Aprovado',
            },
          ]}
        />
      </div>
      <div className={styles.operacaoContainer}>
        <TableAprovado data={operacoes} onVisualizarCliente={handleVisualizarCliente} />

        <Modal open={modalVisible} onCancel={handleCancel} width={900} footer={null}>
          {clienteSelecionado && (
            <StepsSeeClient
              data={clienteSelecionado}
              initialStep={initialStepModal}
              status={clienteSelecionado.etapa}
              handleAceitar={() => handleAceitar(clienteSelecionado.id)}
              handleAprovarMensagem={() => aprovarMandarMensagem(clienteSelecionado)}
              handleAprovar={() => aprovar(clienteSelecionado)}
              handleRecusar={() => handleRecusar(clienteSelecionado.id)}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Aprovado;
