import React from 'react';
import { Button, Select, Divider } from 'antd';

import styles from './style.module.scss';

const HeaderResumoOperacional = ({
  handleFiltrarClick,
  totalContratos,
  searchBarVisible,
  setSelectedCliente,
  handleFilterChange,
  selectedCliente,
  clientNames,
  handleStatusFilterChange,
  selectedYear,
  setSelectedYear,
  handleClearFilters,
  handleCancelarClick,
  statusFilter,
}) => {
  return (
    <>
      <div className={styles.top}>
        <div className={styles.contractCounter}>
          <h1>
            Número de contratos: <span>{totalContratos}</span>
          </h1>
        </div>
        <div className={styles.filtro}>
          <Button onClick={handleFiltrarClick}>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
              <path
                d='M4.66679 3.99984H11.3335L7.99345 8.19984L4.66679 3.99984ZM2.83345 3.73984C4.18012 5.46651 6.66679 8.6665 6.66679 8.6665V12.6665C6.66679 13.0332 6.96679 13.3332 7.33345 13.3332H8.66679C9.03345 13.3332 9.33345 13.0332 9.33345 12.6665V8.6665C9.33345 8.6665 11.8135 5.46651 13.1601 3.73984C13.2365 3.64142 13.2838 3.52352 13.2964 3.39957C13.3091 3.27563 13.2868 3.15061 13.2319 3.03875C13.177 2.9269 13.0918 2.8327 12.986 2.76689C12.8802 2.70108 12.758 2.6663 12.6335 2.6665H3.36012C2.80679 2.6665 2.49345 3.29984 2.83345 3.73984Z'
                fill='#FFFFFF'
              />
            </svg>{' '}
            Filtrar
          </Button>
        </div>
      </div>

      {searchBarVisible && (
        <div className={styles.searchBar}>
          <div className={styles.containerSearchBar}>
            <div>
              <div className={styles.label}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path
                    d='M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z'
                    fill='#E8E8EC'
                  />
                </svg>
                <p>Pesquisar cliente</p>
              </div>
              <Select
                id='cliente-select'
                showSearch
                allowClear
                placeholder='Selecione o cliente'
                optionFilterProp='label'
                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                onChange={(value) => {
                  setSelectedCliente(value);
                  handleFilterChange('cliente', value);
                }}
                value={selectedCliente}
                style={{ marginRight: 8 }}
              >
                {clientNames.map((cliente) => {
                  return (
                    <Option key={cliente.id} value={cliente.id} label={`${cliente.nome} ${cliente.apelido ? `- ${cliente.apelido}` : ''}`}>
                      {cliente.nome} {cliente.apelido && ` (${cliente.apelido})`}
                    </Option>
                  );
                })}
              </Select>
            </div>

            <div>
              <div className={styles.label}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path
                    d='M7.00018 6H17.0002L11.9902 12.3L7.00018 6ZM4.25018 5.61C6.27018 8.2 10.0002 13 10.0002 13V19C10.0002 19.55 10.4502 20 11.0002 20H13.0002C13.5502 20 14.0002 19.55 14.0002 19V13C14.0002 13 17.7202 8.2 19.7402 5.61C19.8548 5.46237 19.9256 5.28553 19.9447 5.09961C19.9637 4.91368 19.9301 4.72615 19.8478 4.55837C19.7655 4.39059 19.6377 4.24929 19.479 4.15058C19.3203 4.05186 19.1371 3.99969 18.9502 4H5.04018C4.21018 4 3.74018 4.95 4.25018 5.61Z'
                    fill='#E8E8EC'
                  />
                </svg>
                <p>Filtrar por operação</p>
              </div>
              <Select value={statusFilter} onChange={handleStatusFilterChange}>
                <Option value='todos'> </Option>
                <Option value='pago'>Pago</Option>
                <Option value='Aberta'>Aberto</Option>
                <Option value='atrasada'>Atrasada</Option>
              </Select>
            </div>

            <div>
              <div className={styles.label}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path
                    d='M7.00018 6H17.0002L11.9902 12.3L7.00018 6ZM4.25018 5.61C6.27018 8.2 10.0002 13 10.0002 13V19C10.0002 19.55 10.4502 20 11.0002 20H13.0002C13.5502 20 14.0002 19.55 14.0002 19V13C14.0002 13 17.7202 8.2 19.7402 5.61C19.8548 5.46237 19.9256 5.28553 19.9447 5.09961C19.9637 4.91368 19.9301 4.72615 19.8478 4.55837C19.7655 4.39059 19.6377 4.24929 19.479 4.15058C19.3203 4.05186 19.1371 3.99969 18.9502 4H5.04018C4.21018 4 3.74018 4.95 4.25018 5.61Z'
                    fill='#E8E8EC'
                  />
                </svg>
                <p>Filtrar por ano</p>
              </div>
              <Select value={selectedYear} onChange={(value) => setSelectedYear(value)} allowClear>
                {[...Array(10)].map((_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <Divider />
          <div className={styles.buttons}>
            <Button onClick={handleClearFilters} className={styles.buttonLimpar}>
              Limpar Filtros
            </Button>
            <Button className={styles.buttonCancelar} onClick={handleCancelarClick}>
              Cancelar
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderResumoOperacional;
