import React from 'react';
import { Table, Tag, Button, Modal, Form, Col, Row, Select, message, Upload, Input, Divider, Switch } from 'antd';
import CurrencyInput from 'react-currency-input-field';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

import styles from './style.module.scss';

const ModalDetalhesDaOperacao = ({
  isModalOpenComprovantes,
  setIsModalOpenComprovantes,
  atualizarParcelaParaPago,
  loadingModal,
  operacaoCompleta,
  valorEmprestimo,
  valorParcela,
  multaAtiva,
  valorTotalOperacao,
  valorMulta,
  valorTotal,
  valorPago,
  setValorPago,
  valorTotalInput,
  splitPayment,
  valorPagoMulta,
  setValorPagoMulta,
  formaPagamento,
  handleFormaPagamentoChange,
  dataPagamento,
  setDataPagamento,
  enviarParaAcordo,
  handleSwitchChange,
  outroPagamento,
  setOutroPagamento,
  handleUpload,
  handlePreview,
  handleComprovanteChange,
  comprovanteFileList,
  uploadButton,
  previewOpen,
  previewTitle,
  setPreviewOpen,
  previewImage,

  diasAtraso,
  valorMultaDiario,
  valorTotalMulta,

  saldoRestanteTotal,

  parcelaAtrasada,
}) => {
  console.log('parcelaAtrasada', parcelaAtrasada);
  return (
    <>
      <Modal
        title='Detalhes da operação'
        open={isModalOpenComprovantes}
        onCancel={() => setIsModalOpenComprovantes(false)}
        footer={[
          <Button key='submit' type='primary' onClick={atualizarParcelaParaPago}>
            {loadingModal ? <LoadingOutlined /> : enviarParaAcordo ? 'Formalizar acordo' : 'Finalizar Pagamento'}
          </Button>,
        ]}
        centered
      >
        <Form layout='vertical'>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label='Cliente'>
                <Input value={operacaoCompleta?.operacao?.cliente?.nome} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Valor do Empréstimo' className={styles.inputEstilizadoDisable}>
                <CurrencyInput
                  id='valor-emprestimo-input'
                  name='valorEmprestimo'
                  className={styles.valueInput}
                  value={valorEmprestimo.toFixed(2)}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  prefix='R$ '
                  disabled
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Valor da Parcela' className={styles.inputEstilizadoDisable}>
                <CurrencyInput id='valor-parcela-input' name='valorParcela' className={styles.valueInput} value={valorParcela} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} decimalsLimit={2} prefix='R$ ' disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            {saldoRestanteTotal && (
              <Col span={12}>
                <Form.Item label='Saldo restante da parcela atual' className={styles.inputEstilizadoDisable}>
                  <CurrencyInput
                    id='saldoRestanteTotal'
                    name='saldoRestanteTotal'
                    className={styles.valueInput}
                    value={saldoRestanteTotal?.toFixed(2)}
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    decimalsLimit={2}
                    prefix='R$ '
                    disabled
                    style={{ width: '100%' }}
                    onValueChange={(value) => setValorPago(value)}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item label='Total a pagar' className={styles.inputEstilizadoDisable}>
                <CurrencyInput
                  id='valor-total-input'
                  name='valorTotal'
                  className={styles.valueInput}
                  value={valorTotal.toFixed(2)}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  prefix='R$ '
                  disabled
                  style={{ width: '100%' }}
                  onValueChange={(value) => setValorPago(value)}
                />
              </Form.Item>
            </Col>

            {/* Verifica se há alguma multa ativa e exibe a seção de multas */}
            <Col span={24}>{parcelaAtrasada?.length > 0 && <h2>Parcelas atrasadas</h2>}</Col>

            {/* Itera sobre cada parcela em atraso e exibe seus dados */}
            {parcelaAtrasada?.length > 0 && parcelaAtrasada?.map((parcela, index) => {
              const valorParcela = parseFloat(parcela.valorParcela || 0).toFixed(2); // Garante duas casas decimais
              const valorMulta = parseFloat(parcela.valorMulta || 0).toFixed(2); // Garante duas casas decimais
              const valorTotal = (parseFloat(valorParcela) + parseFloat(valorMulta)).toFixed(2); // Soma com duas casas decimais

              return (
                <React.Fragment key={parcela.id}>
                  {/* Exibe o valor da parcela */}
                  <Col span={8}>
                    <Form.Item label='Valor da Parcela' className={styles.inputEstilizadoDisable}>
                      <CurrencyInput
                        id={`valor-parcela-${index}`}
                        name='valorParcela'
                        className={styles.valueInput}
                        value={valorParcela}
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        decimalsLimit={2}
                        prefix='R$ '
                        disabled
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>

                  {/* Exibe o valor da multa, mesmo que seja 0 */}
                  <Col span={8}>
                    <Form.Item label='Valor da Multa' className={styles.inputEstilizadoDisable}>
                      <CurrencyInput id={`valor-multa-${index}`} name='valorMulta' className={styles.valueInput} value={valorMulta} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} decimalsLimit={2} prefix='R$ ' disabled style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>

                  {/* Exibe o valor total (parcela + multa) */}
                  <Col span={8}>
                    <Form.Item label='Valor Total' className={styles.inputEstilizadoDisable}>
                      <CurrencyInput id={`valor-total-${index}`} name='valorTotal' className={styles.valueInput} value={valorTotal} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} decimalsLimit={2} prefix='R$ ' disabled style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </React.Fragment>
              );
            })}

            <Col span={8}>
              {multaAtiva && (
                <Form.Item label='Dias de atraso' className={styles.inputEstilizadoDisable}>
                  <CurrencyInput id='valor-multa-input' name='diasAtraso' className={styles.valueInput} value={diasAtraso} decimalsLimit={2} disabled style={{ width: '100%' }} />
                </Form.Item>
              )}
            </Col>
            <Col span={8}>
              {multaAtiva && (
                <Form.Item label='Valor multa diária' className={styles.inputEstilizadoDisable}>
                  <CurrencyInput
                    id='valor-multa-input'
                    name='valorMultaDiario'
                    className={styles.valueInput}
                    value={valorMultaDiario}
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    decimalsLimit={2}
                    prefix='R$ '
                    disabled
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Col>
            <Col span={8}>
              {multaAtiva && (
                <Form.Item label='Valor total da multa' className={styles.inputEstilizadoDisable}>
                  <CurrencyInput
                    id='valor-multa-input'
                    name='valorTotalMulta'
                    className={styles.valueInput}
                    value={valorTotalMulta}
                    intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                    decimalsLimit={2}
                    prefix='R$ '
                    disabled
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Col>

            <Col span={12}>
              <Form.Item label='Data pagamento'>
                <DatePicker selected={dataPagamento} locale='pt-BR' onChange={(date) => setDataPagamento(date)} dateFormat='dd/MM/yyyy' className={styles.customDatePickerStyle} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Valor Total Recebido' className={styles.inputEstilizado}>
                <CurrencyInput
                  id='valor-total-input'
                  name='valorTotalInput'
                  className={styles.valueInput}
                  placeholder='Digite o valor total'
                  value={valorTotalInput}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  onValueChange={(value) => splitPayment(value)} // Use o valor diretamente
                  prefix='R$ '
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Valor Parcela' className={styles.inputEstilizado}>
                <CurrencyInput
                  id='valor-pago-input'
                  name='valorPago'
                  className={styles.valueInput}
                  placeholder='Digite o valor da parcela'
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  value={valorPagoMulta} // Valor atual do estado
                  onValueChange={(value) => setValorPagoMulta(value)} // Remova o parseFloat
                  prefix='R$ '
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Valor Multa' className={styles.inputEstilizado}>
                <CurrencyInput
                  id='valor-pagoMulta-input'
                  name='valorPagoMulta'
                  className={styles.valueInput}
                  placeholder='Digite o valor da multa'
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  value={valorPago} // Valor atual do estado
                  onValueChange={(value) => setValorPago(value)} // Remova o parseFloat
                  prefix='R$ '
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label='Forma de Pagamento'>
                <Select value={formaPagamento} onChange={handleFormaPagamentoChange}>
                  <Option value='' disabled hidden>
                    Selecione a forma de pagamento
                  </Option>
                  <Option value='dinheiro'>Dinheiro</Option>
                  <Option value='pix'>Pix</Option>
                  <Option value='cartao_credito'>Cartão de Crédito</Option>
                  <Option value='cartao_debito'>Cartão de Débito</Option>
                  <Option value='outro'>Outros</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <Form.Item label='Colaborador'>
              <Input value={operacaoCompleta?.operacao?.usuario?.nome} disabled />
            </Form.Item>
          </Col>
          <Form.Item>
            <div className={styles.containerSwitch}>
              <h3>Adicionar contrato em acordos</h3>
              <Switch checked={enviarParaAcordo} onChange={handleSwitchChange} />
            </div>
          </Form.Item>
          {formaPagamento === 'outro' && (
            <Form.Item label='Outro Tipo de Pagamento' style={{ marginTop: '10px' }}>
              <Input value={outroPagamento} onChange={(e) => setOutroPagamento(e.target.value)} placeholder='Digite o tipo de pagamento' style={{ width: '100%' }} />
            </Form.Item>
          )}
        </Form>
        <hr />
        <Upload customRequest={handleUpload} listType='picture-card' fileList={comprovanteFileList} onPreview={handlePreview} onChange={handleComprovanteChange}>
          {comprovanteFileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
          <img alt='example' style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Modal>
    </>
  );
};

export default ModalDetalhesDaOperacao;
