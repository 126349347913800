import React, { useState, useEffect } from 'react';
import { Table, Tag, Button, Modal, Form, Col, Row, Select, message, Upload, Input, Divider, Badge, Space } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import { Breadcrumb, Switch } from 'antd';
import { api } from '../../services/apiClient';
import CurrencyInput from 'react-currency-input-field';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { IoEyeSharp } from 'react-icons/io5';
import { FaWhatsapp } from 'react-icons/fa';
import HeaderResumoOperacional from '../HeaderResumoOperacional';
import ContainerFiltrosDatas from '../ContainerFiltrosDatas';
import ModalDetalhesDaOperacao from '../ModalDetalhesDaOperacao';
import ModalParcelasResumoOperacao from '../ModalParcelasResumoOperacao';
import ModalEnviarMensagem from '../ModalEnviarMensagem';
import { enviarMensagemAPI, formatarComoMoeda, formatarData, formatarNomeCliente, gerarMensagemPagamento, renderizarAcoes, renderizarStatusTag as renderizarStatus, handleUpload, filtrarDadosPorStatus, aplicarFiltros } from '../../utils/tools';
import { TbZoomMoney } from 'react-icons/tb';
import { TbCircleArrowDownRightFilled } from 'react-icons/tb';
import { AiOutlineLogin } from 'react-icons/ai';

const TableColabores = ({
  data,
  onChildUpdate,
  clientNames = [],
  progress = 100,
  selectedDay = null,
  setSelectedDay = null,
  selectedMonth = null,
  setSelectedMonth = null,
  selectedYear = null,
  setSelectedYear = null,
  selectedCliente = null,
  setSelectedCliente = null,
}) => {

  const [valorPago, setValorPago] = useState(0);
  const [valorPagoMulta, setValorPagoMulta] = useState(0);
  const [valorParcela, setValorParcela] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [valorTotalOperacao, setValorTotalOperacao] = useState(0);
  const [valorTotalInput, setValorTotalInput] = useState('');
  const [novaEtapaChange, setNovaEtapaChange] = useState('');
  const [isModalOpenComprovantes, setIsModalOpenComprovantes] = useState(false);
  const [comprovanteFileList, setComprovanteFileList] = useState([]);
  const [formaPagamento, setFormaPagamento] = useState('');
  const [outroPagamento, setOutroPagamento] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [operacaoIdChange, setOperacaoIdChange] = useState(0);
  const [loadingModal, setLoadingModal] = useState(false);
  const [multaAtiva, setMultaAtiva] = useState(false);
  const [valorMulta, setValorMulta] = useState(0);
  const [dataPagamento, setDataPagamento] = useState();
  const [loadingMulta, setLoadingMulta] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [statusFilter, setStatusFilter] = useState('todos');
  const [enviarParaAcordo, setEnviarParaAcordo] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    cliente: null,
    etapas: null,
    cpf: null,
    colaborador: null,
    operacao: null,
    dia: null,
  });

  const [isModalVerclienteOpen, setIsModalVerClienteOpen] = useState(false);
  const [modalVercliente, setModalVerCliente] = useState(false);

  const uniqueClientes = [...new Set(data.map((item) => item.operacao.cliente.nome))];
  const [searchBarVisible, setSearchBarVisible] = useState(true);
  const [saldoRestanteTotal, setSaldoRestanteTotal] = useState(0);
  const [valorEmprestimo, setValorEmprestimo] = useState(0);
  const [parcelaAtrasada, setParcelaAtrasada] = useState(0);
  const [sortedInfo, setSortedInfo] = useState({});
  const [operacaoPaiId, setOperacaoPaiId] = useState();
  const [operacaoCompleta, setOperacaoCompleta] = useState();
  const [visibleData, setVisibleData] = useState([]);
  const [page, setPage] = useState(1);

  const [mensagem, setMensagem] = useState('');
  const [telefone, setTelefone] = useState('');
  const [tokenEnvio, setTokenEnvio] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [diasAtraso, setdiasAtraso] = useState(0);
  const [valorMultaDiario, setvalorMultaDiario] = useState(0);
  const [valorTotalMulta, setvalorTotalMulta] = useState(0);
  const [restanteParcelaPagar, setrestanteParcelaPagar] = useState(0);

  const today = new Date();

  const normalizeUTCDate = (dateString) => {
    const date = new Date(dateString);
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  };

  useEffect(() => {
    const filteredData = data.filter((item) => {
      const itemDate = normalizeUTCDate(item.dataVencimento);
      const monthMatches = selectedMonth ? itemDate.getMonth() + 1 === selectedMonth : true;
      const yearMatches = selectedYear ? itemDate.getFullYear() === selectedYear : true;
      return monthMatches && yearMatches;
    });
    setTableData(filteredData);
    setVisibleData(filteredData.slice(0, 20));
  }, [data, selectedMonth, selectedYear]);

  useEffect(() => {
    setValorTotal(valorParcela + valorMulta);
  }, [valorParcela, valorMulta]);

  const splitPayment = (valor) => {
    const multaCalculada = parseFloat(valorMulta);
    const restante = parseFloat(valor) - multaCalculada;
    setValorTotalInput(valor);
    setValorPagoMulta(restante);
    setValorPago(multaCalculada);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
      loadMoreData();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [visibleData, tableData]);

  const loadMoreData = () => {
    const newData = tableData.slice(visibleData.length, visibleData.length + 20);
    setVisibleData([...visibleData, ...newData]);
    setPage(page + 1);
  };

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);

    const filteredData = filtrarDadosPorStatus(data, value);

    setTableData(filteredData);
    setVisibleData(filteredData.slice(0, 20));
  };

  const handleActionClick = async (record) => {
    setFormaPagamento('');
    setComprovanteFileList([]);
    setOperacaoIdChange(record.id);
    setIsModalOpenComprovantes(true);
    setValorParcela(record.valorParcela);
    setSaldoRestanteTotal(record.operacao.saldoRestanteTotal);
    setValorEmprestimo(record.operacao.valorEmprestimo);
    setParcelaAtrasada(record.parcelasAtrasadas);
    setValorTotal(record.operacao.valorTotal);
    setOperacaoPaiId(record.operacao.id);
    setOperacaoCompleta(record);
    setValorTotalOperacao(record.operacao.valorEmprestimo + record.saldoRestante);
    setDataPagamento(null);
    setrestanteParcelaPagar(record.saldoRestante);
  };

  useEffect(() => {
    if (dataPagamento && operacaoIdChange && valorParcela) {
      calcularMulta(operacaoIdChange, valorParcela, dataPagamento);
    }
  }, [dataPagamento]);

  const calcularMulta = async (id, saldoRestante, dataPagamento) => {
    setLoadingMulta(true);
    try {
      const response = await api.post(`calcular-multa/${id}`, { dataPagamento });
      const { diasAtraso, valorMultaDiaria, valorMultaTotal } = response.data;
      const multaCalculada = parseFloat(valorMultaTotal.toFixed(2));
      setdiasAtraso(diasAtraso);
      setvalorMultaDiario(valorMultaDiaria.toFixed(2));
      setvalorTotalMulta(valorMultaTotal.toFixed(2));
      if (multaCalculada > 0) {
        setMultaAtiva(true);
        setValorMulta(multaCalculada);
        // setValorPago(parseFloat((saldoRestante + multaCalculada).toFixed(2)));
        message.success('Multa calculada com sucesso!');
      }
    } catch (error) {
      console.error('Erro ao calcular multa:', error);
      message.error('Erro ao calcular multa!');
    }
    setLoadingMulta(false);
  };

  const veroperacao = async (operacaoId) => {
    try {
      const response = await api.get(`/operation/${operacaoId}`);

      // Verifica se as operações foram retornadas corretamente
      const { operacoes } = response.data;

      if (operacoes && operacoes.length > 0) {
        const operacao = operacoes[0]; // Supondo que você queira mostrar a primeira operação, ajuste conforme necessário
        setIsModalVerClienteOpen(true);
        setModalVerCliente(operacao.subOperacoes); // Seta as subOperações no modal
      } else {
        message.warning('Nenhuma operação encontrada.');
      }
    } catch (error) {
      console.error('Erro ao carregar a operação:', error.message);
      message.error('Erro ao carregar a operação.');
    }
  };

  const handleSendMessage = async (operacao) => {
    const tokenPolaplon = operacao?.operacao?.usuario?.tokenPolaplon;
    setTokenEnvio(tokenPolaplon);

    if (!tokenPolaplon) {
      message.success('Usuario não tem token cadastrado');
      return;
    }

    setMensagem(gerarMensagemPagamento(operacao));
    setTelefone(operacao?.operacao?.cliente?.celular);
    setModalMensagem(true);
  };

  const enviarMensagem = async () => {
    try {
      await enviarMensagemAPI({ mensagem, telefone, tokenEnvio });
      setModalMensagem(false);
      setMensagem('');
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: 'Ação',
      key: 'action',
      render: (_, record) => (
        <div className={styles.rowButtonsTable}>
          {record.qntdAtrasadas > 0 && (
            <Badge count={record.qntdAtrasadas} offset={[-7, 5]}>
              <Button className={styles.buttonPagar} onClick={() => handleActionClick(record)} type='primary' size='small' disabled={record.status === 'pago'} icon={<AiOutlineLogin style={{ marginRight: 4 }} />}>
                <span>Pagar</span>
              </Button>
            </Badge>
          )}
          {record.qntdAtrasadas === 0 && (
            <Button className={styles.buttonPagar} onClick={() => handleActionClick(record)} type='primary' size='small' disabled={record.status === 'pago'} icon={<AiOutlineLogin style={{ marginRight: 4 }} />}>
              <span>Pagar</span>
            </Button>
          )}
        </div>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => renderizarStatus(status, record.dataVencimento),
      sorter: (a, b) => {
        const statusOrderAsc = ['aberta', 'atrasado', 'pago'];
        const statusOrderDesc = ['pago', 'parcial', 'aberta'];
        const displayStatusA = calcularStatusVencimento(a.status, a.dataVencimento);
        const displayStatusB = calcularStatusVencimento(b.status, b.dataVencimento);
        return sortedInfo.order === 'ascend' ? statusOrderAsc.indexOf(displayStatusA) - statusOrderAsc.indexOf(displayStatusB) : statusOrderDesc.indexOf(displayStatusA) - statusOrderDesc.indexOf(displayStatusB);
      },
      sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => {
        const prefix = record.operacao.tipoOperacao === 'mensal' ? 'M' : record.operacao.tipoOperacao === 'diario' ? 'D' : '';
        const displayId = `${prefix}-${record.operacao.id}`;

        return (
          <span onClick={() => veroperacao(record.operacao.id)} style={{ cursor: 'default', color: '#1890ff' }}>
            {displayId}
          </span>
        );
      },
    },

    {
      title: 'Cliente',
      dataIndex: 'operacao',
      key: 'operacao',
      render: (operacao, record) => {
        const nomeCliente = operacao.cliente.nome ? formatarNomeCliente(operacao.cliente.nome) : '';

        return (
          <div className={styles.containerRowCliente}>
            {/* {record.status !== 'pago' && ( */}
            <Button className={styles.buttonEnviarMensagemWhats} onClick={() => handleSendMessage(record)} size='small'>
              <FaWhatsapp size={20} />
            </Button>
            {/* )} */}
            <span>{nomeCliente}</span>
          </div>
        );
      },
    },
    {
      title: 'Valor da Parcela',
      dataIndex: 'valorParcela',
      key: 'valorParcela',
      render: formatarComoMoeda,
    },
    {
      title: 'Valor Restante',
      dataIndex: 'saldoRestante',
      key: 'saldoRestante',
      render: formatarComoMoeda,
    },
    {
      title: 'Data de Vencimento',
      dataIndex: 'dataVencimento',
      key: 'dataVencimento',
      render: formatarData,
      sorter: (a, b) => new Date(a.dataVencimento) - new Date(b.dataVencimento),
      sortOrder: sortedInfo.columnKey === 'dataVencimento' && sortedInfo.order,
    },
    {
      title: 'Data de Pagamento',
      dataIndex: 'dataPagamento',
      key: 'dataPagamento',
      render: (text) => (text ? formatarData(text) : 'Não pago'),
      sorter: (a, b) => new Date(a.dataVencimento) - new Date(b.dataVencimento),
      sortOrder: sortedInfo.columnKey === 'dataVencimento' && sortedInfo.order,
    },
    {
      title: 'Colaborador',
      dataIndex: 'colaborador',
      key: 'colaborador',
      render: (value, record) => <p>{record.operacao.usuario.nome}</p>,
    },
  ];

  const handleComprovanteChange = ({ fileList }) => setComprovanteFileList(fileList);

  const atualizarParcelaParaPago = async () => {
    setLoadingModal(true);

    try {
      const urlsFotosDocumento = await Promise.all(
        comprovanteFileList.map(async (file) => {
          if (!file.url && !file.response) {
            const response = await handleUpload({ file: file.originFileObj });
            return response;
          }
          return file.url || file.response;
        })
      );

      if (enviarParaAcordo) {
        const valorPagoMultaFloat = parseFloat(valorPagoMulta.replace(',', '.'));

        const dataPago = {
          id: operacaoPaiId,
          valorPago: valorPagoMultaFloat,
          comprovantesNovos: urlsFotosDocumento,
          formaPagamento,
        };
        await api.put(`operacao/acordo`, dataPago);
        setLoadingModal(false);
        onChildUpdate();

        setIsModalOpenComprovantes(false);
        message.success('Operação atualizada com sucesso!');
      } else {
        const valorPagoFloat = parseFloat(valorPago);
        const valorPagoMultaStr = String(valorPagoMulta);

        // Verifica se a vírgula existe na string e a substitui por um ponto, se presente
        const valorPagoMultaFloat = valorPagoMultaStr.includes(',') ? parseFloat(valorPagoMultaStr.replace(',', '.')) : parseFloat(valorPagoMultaStr);

        if (formaPagamento === '') {
          message.error('Não foi possível atualizar operação, selecione uma forma de pagamento!');
          setLoadingModal(false);
          return;
        }
        if (dataPagamento === '' || !dataPagamento) {
          message.error('Não foi possível atualizar operação, selecione uma data de pagamento!');
          setLoadingModal(false);
          return;
        }

        const dataPago = {
          status: novaEtapaChange,
          valorPago: valorPagoMultaFloat,
          valorPagoMulta: valorPagoFloat,
          comprovantesNovos: urlsFotosDocumento,
          formaPagamento,
          dataPagamento,
        };

        await api.put(`/suboperation/${operacaoIdChange}`, dataPago);
        setLoadingModal(false);
        onChildUpdate();

        setIsModalOpenComprovantes(false);
        message.success('Operação atualizada com sucesso!');
      }
    } catch (error) {
      console.log('error', error.message);
      message.error('Não foi possível atualizar operação!');
      setLoadingModal(false);
    }
  };

  const handleFormaPagamentoChange = (value) => {
    setFormaPagamento(value);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type='button'
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handleFiltrarClick = () => {
    setSearchBarVisible(!searchBarVisible);
  };

  const handleCancelarClick = () => {
    setSearchBarVisible(false);
    handleClearFilters();
  };

  const applyFilters = (filters) => {
    const filteredData = aplicarFiltros(data, filters);

    setTableData(filteredData);
    setVisibleData(filteredData.slice(0, 20));
  };

  const handleFilterChange = (filterKey, value) => {
    setActiveFilters({ ...activeFilters, [filterKey]: value });

    applyFilters({ ...activeFilters, [filterKey]: value });
  };

  const handleClearFilters = () => {
    setSelectedCliente(null);
    setSelectedMonth(null);
    setSelectedYear(null);
    setSelectedDay(null);
    setStatusFilter('todos');
    setActiveFilters({
      cliente: null,
      etapas: null,
      cpf: null,
      colaborador: null,
      operacao: null,
    });
    setTableData(data);
    setVisibleData(data.slice(0, 20));
  };

  const handleSwitchChange = (checked) => {
    setEnviarParaAcordo(checked);
  };

  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  const handleDataPagamentoChange = (date) => {
    setDataPagamento(date);
  };

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            title: (
              <>
                <span>Financeiro</span>
              </>
            ),
          },
          {
            title: 'Resumo Operacional',
          },
        ]}
      />
      <HeaderResumoOperacional
        handleFiltrarClick={handleFiltrarClick}
        totalContratos={data.length}
        searchBarVisible={searchBarVisible}
        setSelectedCliente={setSelectedCliente}
        handleFilterChange={handleFilterChange}
        selectedCliente={selectedCliente}
        clientNames={clientNames}
        handleStatusFilterChange={handleStatusFilterChange}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        handleClearFilters={handleClearFilters}
        handleCancelarClick={handleCancelarClick}
        statusFilter={statusFilter}
      />

      <ContainerFiltrosDatas months={months} setSelectedMonth={setSelectedMonth} setSelectedDay={setSelectedDay} selectedMonth={selectedMonth} selectedDay={selectedDay} selectedCliente={selectedCliente} />

      <Table
        columns={columns}
        dataSource={visibleData}
        className={styles.table}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          setSortedInfo(sorter);
        }}
      />
      <ModalDetalhesDaOperacao
        isModalOpenComprovantes={isModalOpenComprovantes}
        setIsModalOpenComprovantes={setIsModalOpenComprovantes}
        atualizarParcelaParaPago={atualizarParcelaParaPago}
        loadingModal={loadingModal}
        operacaoCompleta={operacaoCompleta}
        valorEmprestimo={valorEmprestimo}
        parcelaAtrasada={parcelaAtrasada}
        valorParcela={valorParcela}
        multaAtiva={multaAtiva}
        valorMulta={valorMulta}
        valorTotalOperacao={valorTotalOperacao}
        valorTotal={valorTotal}
        setValorPago={setValorPago}
        valorTotalInput={valorTotalInput}
        splitPayment={splitPayment}
        valorPagoMulta={valorPagoMulta}
        setValorPagoMulta={setValorPagoMulta}
        formaPagamento={formaPagamento}
        handleFormaPagamentoChange={handleFormaPagamentoChange}
        dataPagamento={dataPagamento}
        setDataPagamento={handleDataPagamentoChange}
        enviarParaAcordo={enviarParaAcordo}
        handleSwitchChange={handleSwitchChange}
        outroPagamento={outroPagamento}
        setOutroPagamento={setOutroPagamento}
        handleUpload={handleUpload}
        comprovanteFileList={comprovanteFileList}
        handlePreview={handlePreview}
        handleComprovanteChange={handleComprovanteChange}
        uploadButton={uploadButton}
        previewOpen={previewOpen}
        previewTitle={previewTitle}
        setPreviewOpen={setPreviewOpen}
        previewImage={previewImage}
        valorPago={valorPago}
        diasAtraso={diasAtraso}
        valorMultaDiario={valorMultaDiario}
        valorTotalMulta={valorTotalMulta}
        saldoRestanteTotal={restanteParcelaPagar}
      />

      <ModalParcelasResumoOperacao isModalVerclienteOpen={isModalVerclienteOpen} setIsModalVerClienteOpen={setIsModalVerClienteOpen} modalVercliente={modalVercliente} formatarComoMoeda={formatarComoMoeda} formatarData={formatarData} />

      <ModalEnviarMensagem modalMensagem={modalMensagem} setModalMensagem={setModalMensagem} enviarMensagem={enviarMensagem} mensagem={mensagem} setMensagem={setMensagem} telefone={telefone} setTelefone={setTelefone} />
    </div>
  );
};

export default TableColabores;
