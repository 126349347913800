import React, { useEffect, useState } from 'react';
import { Modal, Select, Button, message, Table, Card } from 'antd';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';

const { Option } = Select;

const ModalMensal = ({ isModalOpen, setIsModalOpen, modalContent, getEtapaID, atualizarEtapaOperacao, formatarComoMoeda, formatarData, operacaoId }) => {
  console.log('operacaoId', operacaoId)
  console.log('modalContent', modalContent)
  const [logs, setLogs] = useState([]);
 
  // Requisição para buscar logs da operação
  useEffect(() => {
    console.log('operacaoId', operacaoId)
    if (isModalOpen && operacaoId) {
      api.get(`/log/capital/${operacaoId}`)
        .then((response) => {
          setLogs(response.data);
        })
        .catch((error) => {      
          message.error('Erro ao buscar logs da operação.');
          console.error(error); 
        });
    }
  }, [isModalOpen, operacaoId]);

  return (
    <Modal
      title='Mensal'
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      width={900}
    >
      {/* Linha com informações do contrato */}
      <div className={styles.contractInfo}>
        <table className={styles.singleRowTable}>
          <tbody>
            <tr>
              <td><strong>Status:</strong> {modalContent[0]?.status}</td>
              <td><strong>Valor Total:</strong> {formatarComoMoeda(modalContent[0]?.valorTotal)}</td>
              <td><strong>Saldo Restante:</strong> {formatarComoMoeda(modalContent[0]?.saldoRestanteTotal)}</td>
              <td><strong>Data de Vencimento:</strong> {formatarData(modalContent[0]?.dataVencimento)}</td>
              <td><strong>Número de Parcelas:</strong> {modalContent[0]?.numeroParcelas}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Tabela de logs da operação */}
      <div className={styles.logsTable}>
        <h3>Logs da Operação</h3>
        <Table
          dataSource={logs}
          columns={[
            { title: 'Data', dataIndex: 'data', key: 'data', render: (text) => formatarData(text) },
            { title: 'Valor Anterior', dataIndex: 'valorAnterior', key: 'valorAnterior', render: (value) => formatarComoMoeda(value) },
            { title: 'Valor Atual', dataIndex: 'valorAtual', key: 'valorAtual', render: (value) => formatarComoMoeda(value) },
            { title: 'Valor Debitado', dataIndex: 'valorDebitado', key: 'valorDebitado', render: (value) => formatarComoMoeda(value) },
          ]}
          rowKey="id"
          pagination={false}
        />
      </div>

      {/* Exibição detalhada das suboperações */}
      <div className={styles.cardContainer}>
      {modalContent && modalContent.map((row, index) => (
        <Card key={index} className={styles.parcelaCard}>
          <div className={styles.parcelaHeader}>
            <h3>Parcela {row.numeroParcela}</h3>
            <Select
              defaultValue={row.status}
              onChange={(value) => atualizarEtapaOperacao(row.id, value, false)}
              className={styles.statusSelect}
            >
              <Option value='aberto'>Em aberto</Option>
              <Option value='parcial'>Parcial</Option>
              <Option value='renegociar'>Renegociar</Option>
              <Option value='pendente'>Pendente</Option>
              <Option value='atrasado'>Atrasado</Option>
              <Option value='finalizado'>Pago</Option>
            </Select>
          </div>
          <div className={styles.parcelaContent}>
            <p><strong>Valor Pago:</strong> {formatarComoMoeda(row.valorPago)}</p>
            <p><strong>Valor da Parcela:</strong> {formatarComoMoeda(row.valorParcela)}</p>
            <p><strong>Restante a Pagar:</strong> {formatarComoMoeda(row.saldoRestante)}</p>
            <p>
              <strong>Status Pagamento:</strong> 
              {row.statusPagamentoAgente === 'Pago' ? 
                <span className={styles.pagoValue}>Pago</span> : 
                <span className={styles.naoPagoValue}>Não pago</span>}
            </p>
            <p>
              <strong>Confirmação de Pagamento:</strong> 
              {row.confirmacaoAnalista ? 
                <span className={styles.pagoValue}>Pago</span> : 
                <span className={styles.naoPagoValue}>Não pago</span>}
            </p>
            <p><strong>Data de Vencimento:</strong> {formatarData(row.dataVencimento)}</p>
          </div>
          <Button className={styles.comprovanteButton}>Ver Comprovantes</Button>
        </Card>
      ))}
    </div>
    </Modal>
  );
};

export default ModalMensal;
