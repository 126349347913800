import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { api } from '../../../services/apiClient';
import TableColabores from '../../../Components/TableColabores';

const ContratosGestao = () => {
  const [subOperacoes, setSubOperacoes] = useState([]);
  const [clientNames, setClientNames] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [progress, setProgress] = useState(0);

  const fetchSubOperacoes = async (params = {}) => {
    try {
      const queryParams = new URLSearchParams(params);
      const response = await api.get(`/suboperation?${queryParams.toString()}`);
      setProgress(100);
      setSubOperacoes(response.data);
    } catch (error) {
      console.error(error);
      console.log('error', error);
    }
  };

  const fetchNameClient = async () => {
    try { 
      const response = await api.get(`/client-name`);
      setProgress(100);
      setClientNames(response.data);
    } catch (error) {
      console.error(error);
      console.log('error', error);
    }
  };

  // Função que coleta os parâmetros e chama a API
  const buildQueryParams = () => {
    const queryParams = {};

    if (selectedDay) queryParams.dia = selectedDay;
    if (selectedMonth) queryParams.mes = selectedMonth;
    if (selectedYear) queryParams.ano = selectedYear;
    if (selectedCliente) queryParams.clientId = selectedCliente;

    console.log('queryParams', queryParams)
    return queryParams;
  };

  // Chamadas iniciais
  useEffect(() => {
    fetchSubOperacoes(buildQueryParams());
  }, [selectedDay, selectedMonth, selectedYear, selectedCliente]);

  useEffect(() => {
    fetchSubOperacoes(buildQueryParams());
    fetchNameClient();
  }, []);

  // Função chamada pelo child para atualizar a tabela
  const onChildUpdate = () => {
    fetchSubOperacoes(buildQueryParams()); // Manter os mesmos filtros
    console.log('Tabela atualizada');
  };

  return (
    <TableColabores
      selectedDay={selectedDay}
      setSelectedDay={setSelectedDay}
      selectedMonth={selectedMonth}
      setSelectedMonth={setSelectedMonth}
      selectedYear={selectedYear}
      setSelectedYear={setSelectedYear}
      data={subOperacoes}
      onChildUpdate={onChildUpdate}
      progress={progress}
      clientNames={clientNames}
      selectedCliente={selectedCliente}
      setSelectedCliente={setSelectedCliente}
    />
  );
};

export default ContratosGestao;
