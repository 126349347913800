import React from 'react';
import { Table, Tag, Button, Modal, Form, Col, Row, Select, message, Upload, Input, Divider } from 'antd';
import styles from './style.module.scss';

const ContainerFiltrosDatas = ({ months, setSelectedMonth, setSelectedDay, selectedMonth, selectedDay, selectedCliente }) => {
 
  const currentDate = new Date();
  const currentDay = currentDate.getDate(); // Dia atual (1-31)
  const currentMonth = currentDate.getMonth() + 1; // Mês atual (1-12)

  return (
    <>
      <div className={styles.containerFiltrosDatas}>
        <div style={{ display: 'flex', justifyContent: 'center', overflowX: 'auto', marginTop: '20px', width: '100%' }}>
          {months.map((month, index) => (
            <div
              key={index + 1}
              onClick={() => setSelectedMonth(index + 1)}
              style={{
                padding: '10px',
                margin: '5px',
                borderRadius: '5px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '100px',
                border: selectedMonth === index + 1 
                  ? '2px solid #000'
                  : selectedCliente === null && selectedMonth === null && currentMonth === index + 1
                    ? '2px solid #000'
                    : '1px solid #ccc',
              }}
            >
              {month}
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', overflowX: 'auto', marginTop: '20px', width: '100%' }}>
          {[...Array(31)].map((_, i) => (
            <div
              key={i + 1}
              onClick={() => setSelectedDay(i + 1)}
              style={{
                padding: '10px',
                margin: '5px',
                borderRadius: '5px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '40px',
                border: selectedDay === i + 1 
                  ? '2px solid #000'
                  : selectedCliente === null && selectedDay === null && currentDay === i + 1
                    ? '2px solid #000'
                    : '1px solid #ccc',
              }}
            >
              {i + 1}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ContainerFiltrosDatas;